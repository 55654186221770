import {
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    ListItem,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import React from "react";
import { LoadingButton } from "@mui/lab";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { eventDetails } from "../../../../../../../../../../redux/slices/eventDataSlice";

const InputEmailDialog = ({
    isDialogOpen,
    handleCloseDialog,
    loader,
    downloadType,
    handleSubmit,
    onSubmit
}) => {
    const {
        register,
        watch,
        resetField,
        formState: { errors },
        setError,
        trigger
    } = useForm();
    const eventData = useSelector(eventDetails);

    const [chipData, setChipData] = React.useState([eventData?.owner?.email]);

    const handleAddOption = async () => {
        const isValid = await trigger("email_list");

        const isEmailExists = chipData.includes(watch("email_list"));

        // throw error if email already exists
        if (isEmailExists) {
            setError("email_list", { message: "Email is already added" });
            return;
        }
        // if email is valid, add to list of chip data
        if (isValid) {
            const option = watch("email_list");
            option && setChipData((chipData) => [...chipData, option]);
            resetField("email_list");
        }
    };
    const handleDeleteOption = (chipToDelete) => () => {
        setChipData((chips) => chips.filter((chip) => chip !== chipToDelete));
    };
    return (
        <Dialog
            fullWidth
            disableEnforceFocus
            disableEscapeKeyDown
            open={isDialogOpen}
            onClose={handleCloseDialog}
        >
            <DialogTitle sx={{ p: "0.5rem 1rem" }}>
                <IconButton
                    onClick={() => handleCloseDialog()}
                    sx={{
                        position: "absolute",
                        right: "0.2rem",
                        top: "0.2rem"
                    }}
                >
                    <CloseIcon fontSize="medium" />
                </IconButton>
                <Typography
                    sx={{
                        fontSize: { xs: "1.5rem", sm: "1.5rem" }
                    }}
                >
                    Input Email
                </Typography>
            </DialogTitle>
            <DialogContent
                dividers={true}
                sx={{
                    p: { xs: "1rem", md: "1rem" }
                }}
            >
                <Stack>
                    <Typography
                        sx={{ fontSize: "0.9rem", color: "gray", my: 1 }}
                    >
                        Note: Link to download these QR codes will be sent via
                        mail. Kindly make sure you download these QR Codes
                        within a week as this link will expire after a week.
                    </Typography>
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={{ xs: 1, sm: 2 }}
                    >
                        <TextField
                            fullWidth
                            color="warning"
                            label="Add Email"
                            type="email"
                            error={errors?.email_list ? true : false}
                            helperText={
                                errors.email_list
                                    ? errors.email_list.message
                                    : ""
                            }
                            inputProps={{
                                onKeyDown: (e) => {
                                    if (e.key === "Enter") {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        document
                                            .getElementById("addOption")
                                            .click();
                                    }
                                }
                            }}
                            {...register("email_list", {
                                required: "Email is required",
                                pattern: {
                                    value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/, // regex pattern to validate email address
                                    message: "Enter a valid email address"
                                },
                                onChange: () => {
                                    trigger("email_list"); // Re-validate on typing
                                }
                            })}
                        />
                        <Button
                            id="addOption"
                            color="warning"
                            sx={{ fontSize: "2rem", p: 0, minWidth: "1rem" }}
                            onClick={handleAddOption}
                        >
                            +
                        </Button>
                    </Stack>
                    <Typography sx={{ mt: 1 }}>Send To:</Typography>
                    {chipData.length > 0 ? (
                        <Stack direction="row" flexWrap="wrap" sx={{ p: 0 }}>
                            {chipData.map((c, i) => (
                                <ListItem
                                    key={i}
                                    sx={{ p: 0.5, width: "auto" }}
                                >
                                    <Chip
                                        color="warning"
                                        label={c}
                                        onDelete={handleDeleteOption(c)}
                                    />
                                </ListItem>
                            ))}
                        </Stack>
                    ) : null}
                </Stack>
            </DialogContent>
            <Divider />
            <DialogActions sx={{ display: "flex", justifyContent: "flex-end" }}>
                <LoadingButton
                    type="button"
                    onClick={(e) => {
                        handleSubmit(onSubmit(null, e, chipData));
                    }}
                    id={downloadType}
                    variant="contained"
                    color="warning"
                    disabled={loader.inputEmail || chipData.length === 0}
                    loading={loader.inputEmail ? true : false}
                    loadingPosition={loader.inputEmail ? "start" : null}
                    startIcon={loader.inputEmail ? <SaveIcon /> : null}
                >
                    Send
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

export default InputEmailDialog;
