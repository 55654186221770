import {
    Box,
    Button,
    Divider,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Skeleton,
    Stack,
    TextField,
    Typography,
    useTheme
} from "@mui/material";
import React, { useMemo } from "react";
import { FileUploader } from "react-drag-drop-files";
import { Controller } from "react-hook-form";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import InfoRounded from "@mui/icons-material/InfoRounded";
import sendTicketGuestsWithFacilityCsv from "../../../../../../../../../../../assets/csvfile/Send Passes Format Wowsly With Facility.csv";
import sendTicketGuestsWithoutFacilityCsv from "../../../../../../../../../../../assets/csvfile/Send Passes Format Wowsly Without Facility.csv";
import { capitalize } from "lodash";
import { toast } from "react-toastify";

const SetUp = ({
    availablePasses,
    register,
    errors,
    control,
    watch,
    getValues,
    facilityCount,
    templateId,
    messageTemplates,
    templateFields,
    handleFileChange,
    file,
    fileInputError
}) => {
    const theme = useTheme();

    const messagePreviewText = useMemo(() => {
        if (messageTemplates) {
            const template = messageTemplates.find((t) => t.id === templateId);
            if (!template) return "";
            const values = getValues();
            return template.template_string.replace(
                /\{\{(.+?)\}\}/g,
                (matching, value) => {
                    return values[value.trim()]
                        ? values[value.trim()]
                        : matching;
                }
            );
        }
    }, [templateId, watch()]);

    return (
        <Box sx={{ mt: 3 }}>
            <Typography>Please select the csv of guests</Typography>
            <Button
                fullWidth
                href={
                    facilityCount > 0
                        ? sendTicketGuestsWithFacilityCsv
                        : sendTicketGuestsWithoutFacilityCsv
                }
                download="Guests List.csv"
                color="warning"
                variant="outlined"
                sx={{ color: "#FF8359", my: 2, p: 2 }}
            >
                Download Sample Csv File
            </Button>
            <Divider />
            <Box sx={{ mt: 2 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography
                            sx={{
                                fontWeight: "500",
                                fontSize: "15px",
                                display: "inline-block"
                            }}
                        >
                            Available:
                        </Typography>
                        <Typography
                            sx={{
                                ml: 1,
                                color: "#6F738B",
                                display: "inline-block"
                            }}
                        >
                            {availablePasses}
                        </Typography>
                    </Grid>
                    {messageTemplates ? (
                        <>
                            <Grid item xs={12}>
                                {messageTemplates.length > 0 ? (
                                    <FormControl fullWidth color="warning">
                                        <InputLabel>
                                            Select Message Template
                                        </InputLabel>
                                        <Controller
                                            name="templateId"
                                            control={control}
                                            render={({
                                                field: { onChange, value }
                                            }) => (
                                                <Select
                                                    fullWidth
                                                    label="Select Message Template"
                                                    value={value}
                                                    onChange={onChange}
                                                >
                                                    {messageTemplates.map(
                                                        (o, idx) => (
                                                            <MenuItem
                                                                key={`template_${idx}`}
                                                                value={o.id}
                                                            >
                                                                {capitalize(
                                                                    o.dotpe_template_id.replaceAll(
                                                                        "_",
                                                                        " "
                                                                    )
                                                                )}
                                                            </MenuItem>
                                                        )
                                                    )}
                                                </Select>
                                            )}
                                        />
                                    </FormControl>
                                ) : null}
                            </Grid>
                            {templateFields.map((fieldName) => (
                                <React.Fragment key={fieldName[0]}>
                                    {fieldName[1].isEditable ? (
                                        <Grid item xs={12}>
                                            <TextField
                                                required
                                                fullWidth
                                                color="warning"
                                                label={capitalize(fieldName[0])}
                                                error={
                                                    errors?.[fieldName]
                                                        ? true
                                                        : false
                                                }
                                                helperText={
                                                    errors[fieldName[0]]
                                                        ? errors[fieldName[0]]
                                                              .message
                                                        : null
                                                }
                                                {...register(fieldName[0])}
                                            />
                                        </Grid>
                                    ) : null}
                                </React.Fragment>
                            ))}
                            <Grid item xs={12}>
                                <Divider sx={{ my: 2 }} />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    multiline
                                    fullWidth
                                    color="warning"
                                    label="Message Preview"
                                    value={messagePreviewText}
                                />
                            </Grid>
                            <Grid item xs={12}></Grid>
                            <Grid item xs={12}>
                                <FileUploader
                                    name="guestList"
                                    handleChange={handleFileChange}
                                    types={["csv"]}
                                    classes="group-upload-csv-drag-drop-area"
                                    required={!file}
                                    maxSize={5}
                                    children={
                                        <Stack
                                            spacing={1}
                                            alignItems="center"
                                            justifyContent="center"
                                            sx={{
                                                height: "100%",
                                                borderRadius: "0.5rem",
                                                border: `3.5px dashed ${theme.palette.primaryColor}`,
                                                ":hover": {
                                                    cursor: "pointer"
                                                }
                                            }}
                                        >
                                            <DriveFolderUploadIcon
                                                sx={{
                                                    fontSize: "2rem",
                                                    color: theme.palette
                                                        .primaryColor
                                                }}
                                            />
                                            <Stack
                                                spacing={1}
                                                direction="row"
                                                justifyContent="center"
                                                alignItems="center"
                                            >
                                                {file ? (
                                                    <CheckCircleIcon color="success" />
                                                ) : null}
                                                <Typography
                                                    sx={{
                                                        color: "#6F738B",
                                                        fontSize: "0.9rem"
                                                    }}
                                                >
                                                    {file
                                                        ? "Uploaded Successfully"
                                                        : "Drop or Upload Guest List CSV File"}
                                                </Typography>
                                            </Stack>

                                            {file ? (
                                                <Typography
                                                    sx={{
                                                        color: "#6F738B",
                                                        fontSize: "0.9rem"
                                                    }}
                                                >
                                                    File name: {file.name}
                                                </Typography>
                                            ) : null}
                                        </Stack>
                                    }
                                    onSizeError={() => {
                                        toast.error(
                                            "Maximum upload size for file is 5MB",
                                            {
                                                position: "top-right",
                                                theme: "dark"
                                            }
                                        );
                                    }}
                                    dropMessageStyle={{
                                        backgroundColor: "red"
                                    }}
                                />
                                {fileInputError ? (
                                    <p style={{ color: "red" }}>
                                        {fileInputError}
                                    </p>
                                ) : null}
                                <Box
                                    display="flex"
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                    gap={0.5}
                                    mt={0.5}
                                >
                                    <InfoRounded fontSize="0.75rem"></InfoRounded>
                                    <Typography variant="caption">
                                        Please keep in mind that only available
                                        numbers of tickets will be sent. Rest
                                        entries in CSV will be ignored.
                                    </Typography>
                                </Box>
                            </Grid>
                        </>
                    ) : (
                        <Grid item xs={12}>
                            <Skeleton
                                animation="wave"
                                height="25.5rem"
                                sx={{ flex: 1 }}
                            ></Skeleton>
                        </Grid>
                    )}
                </Grid>
            </Box>
        </Box>
    );
};

export default SetUp;
