import SaveIcon from '@mui/icons-material/Save';
import VerifiedIcon from '@mui/icons-material/Verified';
import { LoadingButton } from "@mui/lab";
import { Chip, useMediaQuery } from "@mui/material";
import React from "react";

const QRTicketsPayment = ({ loading, paymentDone, type }) => {
    const isSmallDevices = useMediaQuery("(max-width:400px)");
    return (
        <>
            {paymentDone ?
                <Chip
                    label="Paid" color='success' icon={<VerifiedIcon />}
                />
                :
                <LoadingButton
                    type="submit"
                    id={type==="Download" ? "qrPasses" : "SendTickets"}
                    loading={loading}
                    startIcon={loading ? <SaveIcon /> : ''}
                    loadingPosition={loading ? "start" : null}
                    sx={{
                        color: 'white',
                        borderRadius: '1.1rem',
                        backgroundColor: loading ? 'lightgray' : '#1F274A',
                        ":hover": {
                            backgroundColor: '#1F274A',
                        },
                        fontSize: isSmallDevices ? "0.805rem" : "auto", lineHeight: isSmallDevices ? "1.25" : "auto"
                    }}
                >
                    {loading ? 'Processing..' : `Pay`}
                </LoadingButton>
            }
        </>
    )
};

export default QRTicketsPayment;
