import React, { useContext, useEffect, useState } from "react";
import {
    Button,
    Box,
    Dialog,
    DialogContent,
    useTheme,
    IconButton,
    useMediaQuery,
    DialogTitle,
    Typography,
    Divider,
    DialogActions,
    Grid,
    TextField,
    Stack
} from "@mui/material";
import { NoteAddRounded } from "@mui/icons-material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import { parseDecimalNumber } from "../../../../../../../../../../utils/functions/getFormattedNumber";
import { createOrder } from "../../../../../../../../../../services/Payment/paymentGateway";
import { loadScript } from "../../../../../../../../../../utils/functions/loadScript";
import { ticketCurrencies } from "../../../../../../../../../../utils/constants/ticketCurrency";
import { userDetails } from "../../../../../../../../../../redux/slices/userDataSlice";
import { razorPayModalConfig } from "../../../../../../../../../../utils/constants/razorPayModalConfig";
import getGeneratedOrSendTicketCharges from "../../../../../../../../../../utils/functions/getGeneratedOrSendTicketCharges";
import logo from "../../../../../../../../../../assets/WowslyLogo.png";
import AddQuantity from "./Steps/AddQuantity";
import PurchaseOrder from "./Steps/PurchaseOrder";
import DownloadQR from "./Steps/DownloadQR";
import CloseIcon from "@mui/icons-material/Close";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import WebInvitationStepper from "../WebInvitationStepper";
import MobileInvitationStepper from "../MobileInvitationStepper";
import * as yup from "yup";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import { isAdminUser } from "../../../../../../../../../../utils/functions/isAdminUser";
import { generateQRPassesForEvent } from "../../../../../../../../../../services/wEvents/eventGuests";
import { eventDetails } from "../../../../../../../../../../redux/slices/eventDataSlice";
import { EventSettingsContext } from "../../../..";
import InputEmailDialog from "./InputEmailDialog";

const GenerateQRTicketsDialog = ({ ticket }) => {
    const theme = useTheme();
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isCloseConfirmationDialogOpen, setIsCloseConfirmationDialogOpen] =
        useState(false);
    const [loader, setLoader] = useState({
        qrPasses: false,
        qrCodeValues: false,
        inputEmail: false
    });

    const [paymentDone, setPayment] = useState(false);
    const [generateQRActiveStep, setGenerateQRActiveStep] = useState(0);
    const [loadingPayment, setLoadingPayment] = useState(false);
    const [inputEmailDialog, setInputEmailDialog] = useState(false);
    const [downloadType, setDownloadType] = useState(null);

    const eventData = useSelector(eventDetails);
    const userData = useSelector(userDetails);
    const isUserAdmin = isAdminUser(userData?.mobile);

    const isMobileScreen = useMediaQuery("(max-width:600px)");

    const razorPayCurrency = ticketCurrencies.find(
        (t) => t.value === "rupees"
    )?.currency;

    const GST_PERCENT = process.env.REACT_APP_GST_PERCENT_FOR_SEND_MESSAGE;

    const { tickets, setTickets } = useContext(EventSettingsContext);
    const steps = ["Add Quantity", "Make Payment", "Download"];
    const isSmallDevices = useMediaQuery("(max-width:400px)");

    const closeDialogConfirmation = () => {
        setIsCloseConfirmationDialogOpen(true);
    };

    const handleCloseDialog = (
        event,
        reason,
        openCloseConfirmationDialog = true // this will be false when download button is clicked
    ) => {
        if (reason && reason === "backdropClick") return;
        if (isUserAdmin) {
            setLoader({ ...loader, qrCodeValues: false, qrPasses: false });
        } else {
            // if the payment is done and event owner attempts to close dialog box then show warning message
            if (
                openCloseConfirmationDialog &&
                ((generateQRActiveStep === 1 && paymentDone) ||
                    generateQRActiveStep === 2) &&
                !isCloseConfirmationDialogOpen
            ) {
                closeDialogConfirmation();
                return;
            }
            setIsCloseConfirmationDialogOpen(false);
            document.body.classList.remove("no-scroll");
            setGenerateQRActiveStep(0);
        }
        reset();
        setPayment(false);
        setIsDialogOpen(false);
    };

    const schema = yup.object({
        passQuantity: yup
            .number()
            .label("Pass quantity")
            .typeError("This field is required")
            .required()
            .integer("This field is required")
            // .max(500)
            .max(ticket?.quantity - ticket?.sold_out)
    });

    const {
        handleSubmit,
        register,
        reset,
        setError,
        formState: { errors },
        watch,
        setValue
    } = useForm({
        defaultValues: {
            passQuantity: 0
        },
        resolver: yupResolver(schema)
    });

    let passSubTotal =
        getGeneratedOrSendTicketCharges(watch("passQuantity")) *
        watch("passQuantity");

    // Adding 1 rupee for each facility
    const includedFacilities = ticket.facilities.filter(
        (facility) => facility.is_included === 1
    );
    const facilityCount = ticket.facilities.filter(
        (facility) => facility.is_included === 1
    ).length;
    const totalFacilityCharge = facilityCount * watch("passQuantity");
    let subTotal = passSubTotal + totalFacilityCharge;

    let gstCharge = (GST_PERCENT / 100) * subTotal;
    gstCharge = parseDecimalNumber(gstCharge, 2);
    const totalAmountToPay = subTotal + gstCharge;

    // if the user is admin generate QR passes/QR code values else perform this function in 3rd step
    // also if passQuantity to generate are <= 100 then directly download else send temporary downloadable url once they are downloaded
    const generateQRPasses = (values, event, emailList) => {
        if (watch("passQuantity") === 0) {
            setError("passQuantity", {
                type: "manual",
                message: "Pass quantity must be greater than or equal to 1"
            });
            return;
        }
        const downloadDataType =
            event.nativeEvent?.submitter?.id || event.target?.id;
        setLoader({ ...loader, [downloadDataType]: true });
        const passQuantity = watch("passQuantity");
        const apiPayload = {
            passQuantity: watch("passQuantity"),
            downloadDataType: downloadDataType
        };

        if (passQuantity <= 100) {
            setLoader({ ...loader, [downloadDataType]: true });
        } else {
            setLoader({ ...loader, inputEmail: true });
            apiPayload["email_list"] = emailList; // it contains list of emails to whom downloadable url must be sent
        }

        generateQRPassesForEvent({
            eventId: eventData?.id,
            ticketId: ticket.id,
            payload: apiPayload
        })
            .then((res) => {
                if (passQuantity <= 100) {
                    if (downloadDataType === "qrCodeValues") {
                        const fileBlob = new Blob([res.data], {
                            type: "text/csv"
                        });
                        const downloadLink = document.createElement("a");
                        document.body.appendChild(downloadLink);
                        const fileUrl = URL.createObjectURL(fileBlob);

                        // Create a link to download the file
                        downloadLink.href = fileUrl;
                        downloadLink.download = `${eventData.title}-${ticket?.id}-QR Code Values.csv`;
                        // Add the link to the DOM and click it
                        downloadLink.click();

                        // Clean up the URL object
                        URL.revokeObjectURL(fileUrl);
                    } else {
                        const url = window.URL.createObjectURL(
                            new Blob([res.data])
                        );
                        const link = document.createElement("a");
                        link.href = url;
                        link.setAttribute(
                            "download",
                            `${eventData?.title
                                .replaceAll(" ", "_")
                                .toLowerCase()}_passes.zip`
                        );
                        document.body.appendChild(link);
                        link.click();
                    }
                }

                const ticketIdx = tickets.findIndex((t) => t.id === ticket?.id);
                if (ticketIdx !== -1) {
                    const updatedTickets = [...tickets];
                    updatedTickets[ticketIdx].sold_out += Number(
                        watch("passQuantity")
                    );
                    setTickets(updatedTickets);
                }

                let toastMessage = null;
                if (passQuantity <= 100) {
                    setLoader({ ...loader, [downloadDataType]: false });
                    toastMessage =
                        downloadDataType === "qrCodeValues"
                            ? "CSV for QR Code values generated!!"
                            : "QR passes are generated";
                } else {
                    setLoader({ ...loader, inputEmail: false });
                    toastMessage =
                        downloadDataType === "qrCodeValues"
                            ? "CSV for QR Code values will be sent via email!!"
                            : "QR passes will be sent via email!!";
                }
                toast.success(toastMessage, {
                    position: "top-right",
                    theme: "dark"
                });

                reset();
                handleInputEmailCloseDialog();
                handleCloseDialog();
            })
            .catch((err) => {
                console.log(err);
                if (err.response?.data) {
                    toast.error(Object.values(err.response.data)[0][0], {
                        position: "top-right",
                        theme: "dark"
                    });
                } else {
                    toast.error("Something went wrong. Please try again!!", {
                        position: "top-right",
                        theme: "dark"
                    });
                }
                passQuantity <= 100
                    ? setLoader({ ...loader, [downloadDataType]: false })
                    : setLoader({ ...loader, inputEmail: false });
            });
    };

    const onSubmit = async (values) => {
        // if (values['passQuantity'] > 500) {
        //     setError('passQuantity', { type: 'manual', message: 'You can generate only 500 passes at a time.' });
        //     return;
        // }
        setLoadingPayment(true);
        const res = await loadScript(
            "https://checkout.razorpay.com/v1/checkout.js"
        );
        if (!res) {
            alert(
                "Failed to load Razorpay payment gateway. Please try again later."
            );
            setLoadingPayment(false);
            return;
        }

        const razorPayPayload = {
            payment_amount: totalAmountToPay * 100, // For rupees send in paisa
            amount_currency: razorPayCurrency,
            receipt: "Wowsly_Generate_Passes"
        };
        createOrder(razorPayPayload)
            .then((result) => {
                console.log(result);
                setLoadingPayment(false);
                const order_id = result.data?.id;
                const options = {
                    key: process.env.REACT_APP_RAZOR_PAY_LIVE_KEY,
                    amount: totalAmountToPay * 100, // Amount in paise
                    currency: razorPayCurrency,
                    name: "Generate Pass Payment",
                    description: "Payment for generating QR passes",
                    notes: {
                        task: "generate_or_send_qr_passes",
                        user_id: userData?.id,
                        ticket_id: ticket.id,
                        currency: razorPayCurrency,
                        gst_percent:
                            process.env.REACT_APP_GST_PERCENT_FOR_SEND_MESSAGE,
                        gst_charges: gstCharge,
                        total_passes: watch("passQuantity"),
                        type: "Generate"
                    },
                    image: logo,
                    order_id: order_id,
                    handler: function (response) {
                        console.log("Done", response);
                        if (
                            "razorpay_payment_id" in response &&
                            "razorpay_order_id" in response &&
                            "razorpay_signature" in response
                        ) {
                            setPayment(true); // Mark payment as successful
                        } else {
                            toast.error(
                                "Payment failed due to some issues, please try again later!!",
                                {
                                    position: "top-right",
                                    theme: "dark"
                                }
                            );
                            setLoadingPayment(false);
                            return;
                        }
                    },
                    modal: {
                        ondismiss: function () {
                            setLoadingPayment(false);
                        }
                    },
                    prefill: {
                        name: userData?.full_name,
                        email: userData?.email ?? null,
                        contact: userData?.mobile
                    },
                    theme: {
                        color: theme.palette.primaryColor
                    },
                    config: razorPayModalConfig.paymentMethodsConfiguration
                };
                const razorpay = new window.Razorpay(options);
                razorpay.open();
                razorpay.on("payment.failed", function (response) {
                    console.log(response);
                    setLoadingPayment(false);
                    toast.error(response.error.description, {
                        position: "top-right",
                        theme: "dark"
                    });
                });
            })
            .catch((err) => {
                console.log(err);
                setLoadingPayment(false);
                toast.error(
                    err.response?.data?.error ||
                        "Something went wrong. Please try again!!",
                    {
                        position: "top-right",
                        theme: "dark"
                    }
                );
            });
    };

    const handleNext = () => {
        if (generateQRActiveStep === 0) {
            const passQuantity = watch("passQuantity");
            if (passQuantity === 0) {
                setError("passQuantity", {
                    type: "manual",
                    message: "Pass quantity must be greater than or equal to 1"
                });
                return;
            } else if (isNaN(passQuantity) || passQuantity > availablePasses) {
                // isNaN(passQuantity) is to check if passQuantity is empty
                return;
            }
        } else if (generateQRActiveStep === 1 && !paymentDone) {
            toast.error("Please make payment first!!", {
                position: "top-right",
                theme: "dark"
            });
            return;
        }
        setGenerateQRActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setGenerateQRActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const availablePasses = ticket?.quantity - ticket?.sold_out;

    const getStepContent = (stepKey) => {
        switch (stepKey) {
            case 0:
                return (
                    <AddQuantity
                        availablePasses={availablePasses}
                        register={register}
                        errors={errors}
                        setValue={setValue}
                    />
                );
            case 1:
                return (
                    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
                        <PurchaseOrder
                            includedFacilities={includedFacilities}
                            passQuantity={watch("passQuantity")}
                            loading={loadingPayment}
                            paymentDone={paymentDone}
                        />
                    </Box>
                );
            case 2:
                return (
                    <DownloadQR
                        passQuantity={watch("passQuantity")}
                        ticket={ticket}
                        handleClose={handleCloseDialog}
                        handleSubmit1={handleSubmit}
                    />
                );
            default:
                return <></>;
        }
    };

    const handleInputEmailCloseDialog = (event, reason) => {
        if (reason && reason === "backdropClick") return;
        setInputEmailDialog(false);
    };

    // if the user is admin then they can directly generate QR passes/QR code values else user needs to complete stepper function
    return (
        <>
            <Button
                variant="outlined"
                color="warning"
                size="small"
                disabled={ticket?.quantity - ticket?.sold_out === 0}
                startIcon={<NoteAddRounded />}
                onClick={() => setIsDialogOpen(true)}
                sx={{ borderColor: "#FF8359", color: "#FF8359" }}
            >
                Generate QR Passes
            </Button>
            {isUserAdmin ? (
                <>
                    <Dialog open={isDialogOpen} onClose={handleCloseDialog}>
                        <DialogTitle sx={{ p: "0.5rem 1rem" }}>
                            <IconButton
                                onClick={() => handleCloseDialog()}
                                sx={{
                                    position: "absolute",
                                    right: "0.2rem",
                                    top: "0.2rem"
                                }}
                            >
                                <CloseIcon fontSize="medium" />
                            </IconButton>
                            <Typography
                                sx={{
                                    fontSize: { xs: "1.5rem", sm: "1.5rem" }
                                }}
                            >
                                Generate QR Passes
                            </Typography>
                        </DialogTitle>
                        <form
                            autoComplete="off"
                            onSubmit={handleSubmit(generateQRPasses)}
                        >
                            <DialogContent dividers={true}>
                                {ticket?.quantity - ticket?.sold_out === 0 ? (
                                    <Typography>
                                        You have generated all the available
                                        passes.
                                    </Typography>
                                ) : (
                                    <Box pb={2}>
                                        <Typography>
                                            Please select the number of passes
                                            you want to generate
                                        </Typography>
                                        <Box sx={{ mt: 2 }}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <Typography
                                                        sx={{
                                                            fontWeight: "500",
                                                            fontSize: "15px",
                                                            display:
                                                                "inline-block"
                                                        }}
                                                    >
                                                        Available:
                                                    </Typography>
                                                    <Typography
                                                        sx={{
                                                            ml: 1,
                                                            color: "#6F738B",
                                                            display:
                                                                "inline-block"
                                                        }}
                                                    >
                                                        {ticket?.quantity -
                                                            ticket?.sold_out}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        color="warning"
                                                        label="Pass Quantity"
                                                        inputProps={{
                                                            type: "number"
                                                        }}
                                                        error={
                                                            !!errors?.passQuantity
                                                        }
                                                        helperText={
                                                            errors?.passQuantity
                                                                ?.message
                                                        }
                                                        {...register(
                                                            "passQuantity",
                                                            {
                                                                valueAsNumber: true,
                                                                onChange: (
                                                                    e
                                                                ) => {
                                                                    const value =
                                                                        e.target
                                                                            .value
                                                                            ? parseInt(
                                                                                  e
                                                                                      .target
                                                                                      .value
                                                                              )
                                                                            : "";
                                                                    setValue(
                                                                        "passQuantity",
                                                                        value,
                                                                        {
                                                                            shouldValidate: true
                                                                        }
                                                                    );
                                                                }
                                                            }
                                                        )}
                                                        onInput={(e) => {
                                                            // Restrict input to numeric values only
                                                            e.target.value =
                                                                e.target.value.replace(
                                                                    /\D/g,
                                                                    ""
                                                                );
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Box>
                                )}
                            </DialogContent>
                            {ticket?.quantity - ticket?.sold_out !== 0 ? (
                                <>
                                    {watch("passQuantity") <= 100 && (
                                        <DialogActions
                                            sx={{
                                                display: "flex",
                                                justifyContent: "center",
                                                my: 0.5
                                            }}
                                        >
                                            <LoadingButton
                                                type="submit"
                                                id="qrCodeValues"
                                                variant="contained"
                                                color="warning"
                                                disabled={
                                                    loader.qrCodeValues ||
                                                    ticket?.quantity -
                                                        ticket?.sold_out ===
                                                        0
                                                }
                                                loading={
                                                    loader.qrCodeValues
                                                        ? true
                                                        : false
                                                }
                                                loadingPosition={
                                                    loader.qrCodeValues
                                                        ? "start"
                                                        : null
                                                }
                                                startIcon={
                                                    loader.qrCodeValues ? (
                                                        <SaveIcon />
                                                    ) : null
                                                }
                                                sx={{
                                                    fontSize: isSmallDevices
                                                        ? "0.805rem"
                                                        : "auto",
                                                    lineHeight: isSmallDevices
                                                        ? "1.25"
                                                        : "auto"
                                                }}
                                            >
                                                Download CSV
                                            </LoadingButton>

                                            <LoadingButton
                                                type="submit"
                                                id="qrPasses"
                                                variant="contained"
                                                color="warning"
                                                disabled={
                                                    loader.qrPasses ||
                                                    ticket?.quantity -
                                                        ticket?.sold_out ===
                                                        0
                                                }
                                                loading={
                                                    loader.qrPasses
                                                        ? true
                                                        : false
                                                }
                                                loadingPosition={
                                                    loader.qrPasses
                                                        ? "start"
                                                        : null
                                                }
                                                startIcon={
                                                    loader.qrPasses ? (
                                                        <SaveIcon />
                                                    ) : null
                                                }
                                                sx={{
                                                    fontSize: isSmallDevices
                                                        ? "0.805rem"
                                                        : "auto",
                                                    lineHeight: isSmallDevices
                                                        ? "1.25"
                                                        : "auto"
                                                }}
                                            >
                                                Generate & Download Passes
                                            </LoadingButton>
                                        </DialogActions>
                                    )}
                                    {
                                        // if passQuantity is greater than 100 or is empty
                                        (watch("passQuantity") > 100 ||
                                            isNaN(watch("passQuantity"))) && (
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    my: 0.5,
                                                    p: 1
                                                }}
                                            >
                                                <Button
                                                    color="warning"
                                                    id="qrCodeValues"
                                                    variant="contained"
                                                    onClick={() => {
                                                        if (
                                                            watch(
                                                                "passQuantity"
                                                            ) > 100
                                                        ) {
                                                            setInputEmailDialog(
                                                                true
                                                            );
                                                            setDownloadType(
                                                                "qrCodeValues"
                                                            );
                                                        }
                                                    }}
                                                    sx={{
                                                        fontSize: isSmallDevices
                                                            ? "0.805rem"
                                                            : "auto",
                                                        lineHeight:
                                                            isSmallDevices
                                                                ? "1.25"
                                                                : "auto"
                                                    }}
                                                >
                                                    Download CSV
                                                </Button>
                                                <Button
                                                    color="warning"
                                                    id="qrPasses"
                                                    variant="contained"
                                                    onClick={() => {
                                                        if (
                                                            watch(
                                                                "passQuantity"
                                                            ) > 100
                                                        ) {
                                                            setInputEmailDialog(
                                                                true
                                                            );
                                                            setDownloadType(
                                                                "qrPasses"
                                                            );
                                                        }
                                                    }}
                                                    sx={{
                                                        fontSize: isSmallDevices
                                                            ? "0.805rem"
                                                            : "auto",
                                                        lineHeight:
                                                            isSmallDevices
                                                                ? "1.25"
                                                                : "auto",
                                                        marginLeft: 1
                                                    }}
                                                >
                                                    Generate & Download Passes
                                                </Button>
                                            </Box>
                                        )
                                    }
                                    {inputEmailDialog &&
                                        watch("passQuantity") > 0 && (
                                            // get input of emails
                                            <InputEmailDialog
                                                isDialogOpen={inputEmailDialog}
                                                handleCloseDialog={
                                                    handleInputEmailCloseDialog
                                                }
                                                loader={loader}
                                                downloadType={downloadType}
                                                handleSubmit={handleSubmit}
                                                onSubmit={generateQRPasses}
                                            />
                                        )}
                                </>
                            ) : null}
                        </form>
                    </Dialog>
                </>
            ) : (
                <>
                    <Dialog
                        open={isDialogOpen}
                        maxWidth="md"
                        fullWidth
                        onClose={handleCloseDialog}
                        PaperProps={{ style: { borderRadius: 5 } }}
                        disableEscapeKeyDown
                    >
                        <DialogTitle>
                            <IconButton
                                onClick={handleCloseDialog}
                                sx={{
                                    position: "absolute",
                                    right: "0.2rem",
                                    top: "0.2rem"
                                }}
                            >
                                <CloseIcon fontSize="medium" />
                            </IconButton>
                            <Typography
                                sx={{
                                    fontSize: { xs: "1.5rem", sm: "1.8rem" }
                                }}
                            >
                                {"Generate QR Process"}
                            </Typography>
                        </DialogTitle>
                        <Divider sx={{ mb: 1 }} />
                        <DialogContent
                            sx={{
                                px: "1rem",
                                py: "0rem",
                                marginBottom:
                                    isMobileScreen && generateQRActiveStep === 0
                                        ? "20px"
                                        : "auto"
                            }}
                        >
                            {isMobileScreen ? (
                                <>
                                    <MobileInvitationStepper
                                        steps={steps}
                                        activeStep={generateQRActiveStep}
                                        getStepContent={getStepContent}
                                        handleBack={handleBack}
                                        handleNext={handleNext}
                                        paymentDone={paymentDone}
                                    />
                                </>
                            ) : (
                                <>
                                    <WebInvitationStepper
                                        steps={steps}
                                        activeStep={generateQRActiveStep} //edit to activeStep here
                                        getStepContent={getStepContent}
                                        handleBack={handleBack}
                                        handleNext={handleNext}
                                        paymentDone={paymentDone}
                                    />
                                </>
                            )}
                        </DialogContent>
                    </Dialog>
                    {isCloseConfirmationDialogOpen ? (
                        <Dialog
                            fullWidth
                            open={true}
                            onClose={() =>
                                setIsCloseConfirmationDialogOpen(false)
                            }
                            PaperProps={{
                                sx: {
                                    width: { xs: "80%", sm: "70%", md: "50%" },
                                    m: { xs: 0 }
                                }
                            }}
                        >
                            <DialogContent sx={{ p: "1.5rem 2rem" }}>
                                <Stack
                                    direction="row"
                                    alignItems="center"
                                    spacing={3}
                                >
                                    <WarningAmberRoundedIcon
                                        color="warning"
                                        sx={{
                                            marginRight: 1,
                                            fontSize: "4.5rem"
                                        }}
                                    />
                                    <Stack direction="column" spacing={1}>
                                        <Typography variant="h3">
                                            Are you sure?
                                        </Typography>
                                        <Typography variant="body2">
                                            If you leave this page, you will
                                            have to make the payment again to
                                            Generate Passes. Do you still want
                                            to exit?
                                        </Typography>
                                    </Stack>
                                </Stack>
                            </DialogContent>
                            <Divider />
                            <DialogActions sx={{ mr: 1, my: 0.5 }}>
                                <Button
                                    color="warning"
                                    variant="outlined"
                                    onClick={() => {
                                        handleCloseDialog(false);
                                    }}
                                >
                                    Exit
                                </Button>
                                <Button
                                    variant="contained"
                                    color="warning"
                                    onClick={() =>
                                        setIsCloseConfirmationDialogOpen(false)
                                    }
                                >
                                    Stay
                                </Button>
                            </DialogActions>
                        </Dialog>
                    ) : null}
                </>
            )}
        </>
    );
};

export default GenerateQRTicketsDialog;
