import { Box, Collapse, DialogContent, Skeleton, Stack, Typography, useMediaQuery } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, { useEffect, useState } from 'react';
import pluralize from 'pluralize';
import { getOrdinal } from '../../../../../../utils/functions/getOrdinal';
import { convertUtcToLocal } from '../../../../../../utils/functions/timeConversion';
import _ from 'lodash';
import noGuests from '../../../../../../assets/NoGuests.webp';

const ScanningReports = ({ scanningReports }) => {
  const [showScanningDetails, setScanningDetails] = useState(null);
  const isSmallDevice = useMediaQuery("(max-width:600px)");

  useEffect(() => {
    let updatedShowScanningDetails = {};
    if (scanningReports?.check_in.length > 0) {
      updatedShowScanningDetails.check_in = false;
    }
    if (scanningReports?.facility.length > 0) {
      scanningReports.facility.forEach((facility) => {
        updatedShowScanningDetails[facility.facility_name] = false;
      });
    }
    setScanningDetails(updatedShowScanningDetails);
  }, [scanningReports]);

  const handleToggleCollapse = (key) => {
    setScanningDetails((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };

  const renderCheckInDetails = (record, index) => (
    <Box key={index} sx={{ display: "flex", flexWrap: "wrap", border: '2px solid lightgrey', borderRadius: '0.2rem', p: 1 }}>
      <Typography sx={{ width: "40%" }}>
        <b>{getOrdinal(index + 1)} Check-in:</b>&nbsp; {record?.check_in_count} {pluralize('guest', record?.check_in_count)}
      </Typography>
      <Typography sx={{ width: "48%" }}>
        <b>Check-in Time:</b>&nbsp;
        {convertUtcToLocal(record?.check_in_time).format("Do MMMM YYYY, h:mm A")}
      </Typography>

      {record?.category_check_in_count && (
        <>
          <Typography sx={{ width: "100%" }}>
            <b>Category Check-in:</b>&nbsp;
            {Object.entries(record.category_check_in_count).map(([category, count], idx) => (
              <span key={idx} style={{ marginLeft: "10px" }}>
                {category} : {count}
              </span>
            ))}
          </Typography>
          <Typography sx={{ width: "100%" }}>
            <b>Other Category Check-in:</b>
            <span style={{ marginLeft: "10px" }}>
              {record.optional_category_check_in_count}
            </span>
          </Typography>
        </>
      )}
    </Box>
  );

  const renderFacilityDetails = (facilityData, index) => (
    <Box key={index} sx={{ display: "flex", flexWrap: "wrap", border: '2px solid lightgrey', borderRadius: '0.2rem', p: 1 }}>
      <Typography sx={{ width: "40%" }}>
        <b>{getOrdinal(index + 1)} {facilityData.facility_name}:</b>&nbsp;
        {facilityData.check_in_count} {pluralize('guest', facilityData.check_in_count)}
      </Typography>
      <Typography sx={{ width: "48%" }}>
        <b>Check-in Time:</b>&nbsp;
        {convertUtcToLocal(facilityData.check_in_time).format("Do MMMM YYYY, h:mm A")}
      </Typography>
    </Box>
  );

  return (
    <DialogContent sx={{ p: { xs: '1rem', md: '1rem' }, borderTop: '1px solid rgba(0, 0, 0, 0.12)' }}>
      {scanningReports ? (
        <>
          {Object.keys(showScanningDetails || {}).length > 0 ? (
            <>
              {Object.keys(showScanningDetails).map((key) => (
                <Box key={key} mb={2}>
                  <Stack
                    direction='row'
                    alignItems='center'
                    justifyContent='space-between'
                    onClick={() => handleToggleCollapse(key)}
                    sx={{
                      ":hover": {
                        cursor: 'pointer',
                      }
                    }}>
                    <Typography sx={{ fontSize: { xs: '1.2rem' } }}>
                      {_.capitalize(key === "check_in" ? "check in" : key)}
                    </Typography>
                    <Box sx={{ pr: 0.5 }}>
                      {showScanningDetails[key] ?
                        <ExpandMoreIcon sx={{ color: '#1F274A' }} /> :
                        <ArrowForwardIosIcon sx={{ color: '#1F274A' }} />
                      }
                    </Box>
                  </Stack>
                  <Collapse
                    in={showScanningDetails[key]}
                    unmountOnExit={true}>
                    <Stack spacing={2}>
                      {key === "check_in" ? (
                        scanningReports.check_in.map((record, index) =>
                          renderCheckInDetails(record, index)
                        )
                      ) : (
                        scanningReports.facility
                          .find(facility => facility.facility_name === key)
                          ?.records.map((record, index) =>
                            renderFacilityDetails(record, index)
                          )
                      )}
                    </Stack>
                  </Collapse>
                </Box>
              ))}
            </>
          ) : (
            <Box
              component='img'
              src={noGuests}
              width={isSmallDevice ? '100%' : '30rem'}
              height='25rem'
            />
          )}
        </>
      ) : (
        <Skeleton
          sx={{
            mx: '0.5rem !important',
            p: '0rem !important',
            transform: 'scale(1,1)'
          }}
          height='10rem'
          animation="wave"
        />
      )}
    </DialogContent>
  );
};

export default ScanningReports;