export const ForceMetaUpdate = (title, description, image, url) => {
    const updateMeta = (selector, attribute, value) => {
        let element = document.querySelector(selector);
        if (!element) {
            element = document.createElement(selector.split('[')[0]);
            const [attr, val] = selector.match(/\[(.*?)\]/)[1].split('=');
            element.setAttribute(attr.replace(/"/g, ''), val.replace(/"/g, ''));
            document.head.appendChild(element);
        }
        element.setAttribute(attribute, value);
    };

    const defaultImage = `${window.location.origin}/wowslyFavicon.webp`;
    const pageUrl = url || window.location.href;

    // Basic Meta
    document.title = title ? `${title} | Wowsly App` : 'Wowsly App';
    updateMeta('meta[name="description"]', 'content', description || 'Create and manage your events with Wowsly');

    // OpenGraph
    updateMeta('meta[property="og:title"]', 'content', title ? `${title} | Wowsly App` : 'Wowsly App');
    updateMeta('meta[property="og:description"]', 'content', description || 'Create and manage your events with Wowsly');
    updateMeta('meta[property="og:image"]', 'content', image || defaultImage);
    updateMeta('meta[property="og:url"]', 'content', pageUrl);
    updateMeta('meta[property="og:type"]', 'content', 'website');

    // Twitter
    updateMeta('meta[name="twitter:card"]', 'content', 'summary_large_image');
    updateMeta('meta[name="twitter:title"]', 'content', title ? `${title} | Wowsly App` : 'Wowsly App');
    updateMeta('meta[name="twitter:description"]', 'content', description || 'Create and manage your events with Wowsly');
    updateMeta('meta[name="twitter:image"]', 'content', image || defaultImage);

    // Favicon
    updateMeta('link[rel="icon"]', 'href', image || defaultImage);
    updateMeta('link[rel="apple-touch-icon"]', 'href', image || defaultImage);

};