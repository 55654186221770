import _ from "lodash";
import React from "react";
import { Stack, Typography, Divider, Box, Tooltip } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

const CostBreakdown = ({ model, watch, charges, actualPrice, gstPercent, priceSymbol, modelObject }) => {
    const gstOnTicketPrice = gstPercent ? _.round((gstPercent / 100) * actualPrice, 2) : 0;

    // Calculate only platform fee (2%) and GST on platform fee (18%)
    const platformFee = _.round((2 / 100) * actualPrice, 2);
    const gstOnPlatformFee = _.round((18 / 100) * platformFee, 2);

    // System handling charges now include only platform fee and GST on platform fee
    const systemHandlingCharges = platformFee + gstOnPlatformFee;

    return (
        <>
            <Box sx={{ width: { xs: '100%', sm: '75%', lg: '65%' }, margin: '1rem auto !important' }}>
                <Typography sx={{ textAlign: 'center', fontWeight: 'bold' }}>Cost Breakdown Structure</Typography>
                <Stack sx={{ border: '2px solid lightgrey', borderRadius: '0.5rem', p: 2, mt: 1 }}>
                    <Stack direction='row' justifyContent='space-between'>
                        <Typography>{model} Price</Typography>
                        <Typography>{actualPrice} <span dangerouslySetInnerHTML={{ __html: priceSymbol }} /></Typography>
                    </Stack>
                    {(watch ? (watch('currency') === 'rupees' && gstPercent !== 0) : (modelObject.currency === 'rupees' && gstPercent !== 0)) ?
                        <Stack direction='row' justifyContent='space-between'>
                            <Typography>GST on {model} Price</Typography>
                            <Typography>{gstOnTicketPrice} <span dangerouslySetInnerHTML={{ __html: priceSymbol }} /></Typography>
                        </Stack>
                        : null
                    }
                    <Divider sx={{ my: 1 }} />
                    <Typography sx={{ color: '#6F738B', fontSize: '.9rem' }}>Other Charges</Typography>
                    <Stack direction='row' justifyContent='space-between' alignItems="baseline">
                        <Typography>
                            System Handling Charges&nbsp;
                            Includes:
                        </Typography>
                        <Typography>{systemHandlingCharges} <span dangerouslySetInnerHTML={{ __html: priceSymbol }} /></Typography>
                    </Stack>
                    <Stack direction='row' justifyContent='space-between'>
                        <Typography>Razorpay Fee</Typography>
                        <Typography>{charges.razorPayFee} <span dangerouslySetInnerHTML={{ __html: priceSymbol }} /></Typography>

                    </Stack>
                    <Box sx={{ display: "flex", alignItems: 'center' }}>
                        <Typography sx={{ color: '#6F738B', fontSize: '.9rem', marginRight: "5px" }}>(~ 2% + 18% GST)</Typography>
                        <Tooltip
                            title={
                                <Typography sx={{ fontSize: '0.8rem' }}>
                                    <span>
                                        Razorpay fees may vary depending upon the payment method used.
                                        For more details, you may refer to Razorpay pricing :
                                        <a
                                            href="https://razorpay.com/pricing/"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            style={{ cursor: 'pointer', textDecoration: 'underline', color: '#ed6c02' }}
                                        >
                                            Click here
                                        </a>
                                    </span>
                                </Typography>
                            }
                            arrow
                        >
                            <InfoIcon sx={{ fontSize: '1rem', cursor: 'pointer', color: 'gray' }} />
                        </Tooltip>
                    </Box>

                    <Divider sx={{ my: 1 }} />

                    <Stack direction='row' justifyContent='space-between'>
                        <Typography>Buyer will pay</Typography>
                        <Typography>
                            {
                                watch ? (watch('extra_charges_from_buyer') ?
                                    _.round(actualPrice + gstOnTicketPrice + charges.razorPayFee + systemHandlingCharges, 2)
                                    : _.round(actualPrice + gstOnTicketPrice, 2)
                                ) : modelObject.purchase_price
                            } <span dangerouslySetInnerHTML={{ __html: priceSymbol }} />
                        </Typography>
                    </Stack>
                    <Stack direction='row' justifyContent='space-between'>
                        <Typography>You will get</Typography>
                        <Typography>
                            {
                                watch ? (watch('extra_charges_from_buyer') ?
                                    _.round(actualPrice + gstOnTicketPrice, 2) :
                                    _.round((actualPrice + gstOnTicketPrice) - charges.razorPayFee - systemHandlingCharges, 2)
                                ) : modelObject.amount_to_owner
                            } <span dangerouslySetInnerHTML={{ __html: priceSymbol }} />
                        </Typography>
                    </Stack>
                </Stack>
            </Box>
        </>
    );
};

export default CostBreakdown;
