import _ from "lodash";

export const getExtraChargesCosting = (actualModelPrice, gst_percent) => {
    const system_charges = 0.01 * actualModelPrice;
    const wowsly_gst_on_system_charges = 0.18 * system_charges;
    const total_system_charge = system_charges + wowsly_gst_on_system_charges;

    // Calculate the base payment platform fees (2% of the actual price)
    const base_payment_platform_fees = 0.02 * actualModelPrice;

    // GST on the base payment platform fees
    const gst_on_platform_fees = 0.18 * base_payment_platform_fees;

    // Total payment platform fees including GST
    const payment_platform_fees = base_payment_platform_fees + gst_on_platform_fees;

    // If gst_percent is provided, calculate the GST amount
    const gst_amount = gst_percent > 0 ? (gst_percent / 100) * actualModelPrice : 0;

    const charges = {
        systemFee: actualModelPrice ? _.round(total_system_charge, 2) : 0,
        razorPayFee: _.round(payment_platform_fees, 2), // Add GST amount if provided
        wowslyGst: _.round(wowsly_gst_on_system_charges, 2),
    };

    return charges;
};
