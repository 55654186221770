import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import SaveIcon from "@mui/icons-material/Save";
import { LoadingButton } from "@mui/lab";
import {
    Box,
    Button,
    Collapse,
    Dialog,
    DialogActions,
    DialogContent,
    IconButton,
    Stack,
    Switch,
    TextField,
    Typography,
    useMediaQuery,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { EventSettingsContext } from ".";
import { EventDetailsContext } from "../../../..";
import CustomRadioGroup from "../../../../../../components/CustomRadioGroup";
import {
    eventDetails,
    update_event_details,
    update_event_self_checkin_field,
    update_event_api_token_field
} from "../../../../../../redux/slices/eventDataSlice";
import { updateEventDetails, sendAPIToken } from "../../../../../../services/wEvents/event";
import {
    getEventForms,
    getEventPaidPayments,
    getEventReceivedPayments,
    getEventTickets
} from "../../../../../../services/wEvents/eventDetails";
import { getCheckInRecords } from "../../../../../../services/wEvents/eventGuests";
import { getCheckedInTickets } from "../../../../../../services/wEvents/eventGuests";
import { manageEventSettingsPayload } from "../../../../../../utils/functions/managePayload";
import TicketTypeOptions from "./TicketTypeOptions";
import { createLinkBankAccount } from "../../../../../../services/bankDetails";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import TokenDialog from "./TokenDialog";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const SettingsForm = ({ handleClose }) => {
    const dispatch = useDispatch();
    const eventDetailsObj = useSelector(eventDetails);
    const [isEmailEnabled, setIsEmailEnabled] = useState(eventDetailsObj?.sender_name ? true : false);
    const [isSendSMSEnabled, setIsSendSMSEnabled] = useState(eventDetailsObj?.event_sms_sender_id ? true : false);
    const [keyFieldShow, setKeyFieldShow] = useState(false);

    const [whatsappFormData, setWhatsappFormData] = useState({
        whatsapp_key: eventDetailsObj?.whatsapp_key || "",
        phone_number_id: eventDetailsObj?.phone_number_id || "",
        templates: eventDetailsObj?.whatsapp_templates?.length > 0 ? eventDetailsObj.whatsapp_templates : [{
            display_name: "Send Ticket",
            template_name: 'qr_code_invite_updated',
            template_id: '',
            ket: Date.now()
        }, {
            display_name: "Send Ticket With details",
            template_name: 'qr_code_invite_one_updated',
            template_id: '',
            ket: Date.now()
        }],

    });

    const [whatsappFormErrors, setWhatsappFormErrors] = useState({
        whatsapp_key: "",
        phone_number_id: "",
        templates: {}
    });
    // if custom email switch is on both fields must be required else both can be empty
    const schema = yup.object().shape({
        sender_name: yup.string()
            .when([], {
                is: () => isEmailEnabled === true,
                then: yup.string().required('Sender name is required'),
                otherwise: yup.string().nullable(),
            }),
        event_email: yup.string()
            .when([], {
                is: () => isEmailEnabled === true,
                then: yup.string().required('Sender email is required'),
                otherwise: yup.string().nullable(),
            }),
        event_sms_api_key: yup.string()
            .when([], {
                is: () => isSendSMSEnabled === true,
                then: yup.string().required('Sender SMS API key is required'),
                otherwise: yup.string().nullable(),
            }),

        event_sms_sender_id: yup.string()
            .when([], {
                is: () => isSendSMSEnabled === true,
                then: yup.string().required('Sender SMS ID is required'),
                otherwise: yup.string().nullable(),
            }),

        whatsapp_key: yup.string()
            .when([], {
                is: () => keyFieldShow === true,
                then: yup.string().required('Whatsapp key is required'),
                otherwise: yup.string().nullable(),
            }),
        phone_number_id: yup.string()
            .when([], {
                is: () => keyFieldShow === true,
                then: yup.string().required('phone number id is required'),
                otherwise: yup.string().nullable(),
            })

    });

    const {
        handleSubmit,
        register,
        reset,
        setValue,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(schema)
    });
    const { refs } = useContext(EventDetailsContext);
    const {
        setSlideType,
        handleNextClick,
        setRegistrationForm,
        setCheckInRecords,
        setCheckedInTickets,
        totalCheckInRecords,
        per_page_records,
        setPaymentRecords,
        totalPaidRecords,
        totalReceivedRecords
    } = useContext(EventSettingsContext);
    const [loader, setLoader] = useState(false);
    const [isRegistration, setRegistration] = useState(
        eventDetailsObj?.has_registration ? true : false
    );
    const [isSelfCheckedIn, setCheckInCounter] = useState(
        eventDetailsObj?.is_self_check_in ? true : false
    );
    const [token, setToken] = useState(eventDetailsObj.event_token || "");
    const [openTokenModal, setOpenTokenModal] = useState(false);
    const [showTick, setShowTick] = useState(false);
    const [isMultipleTickets, setIsMultipleTickets] = useState(
        eventDetailsObj?.is_multiple_tickets ? true : false
    );

    const handleRegistrationChange = (e) => {
        setRegistration(e.target.checked);
    };
    const handleMultipleTickets = (e) => {
        setIsMultipleTickets(e.target.checked);
        setValue("is_multiple_tickets", e.target.checked);
    }
    const [eventTypeValue, setEventTypeValue] = useState(
        eventDetailsObj?.is_private ? "invite only" : "public"
    );
    const handleEventTypeRadioChange = (event) => {
        setEventTypeValue(event.target.value);
    };

    const [eventTicketType, setEventTicketType] = useState(
        eventDetailsObj?.is_paid ? "paid" : "free"
    );

    const isSmallDevices = useMediaQuery("(max-width:550px)");

    const handleTicketTypeRadioChange = (event) => {
        setEventTicketType(event.target.value);
        dispatch(update_event_details(event.target.value === "paid" ? 1 : 0));
    };

    const handleRegistrationClick = () => {
        getEventForms({ eventId: eventDetailsObj?.id })
            .then((res) => {
                console.log(res.data);
                setRegistrationForm(res.data?.form?.[0]);
                setSlideType("eventRegistration");
                handleNextClick();
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handlePaymentsClick = () => {
        setSlideType("eventPayments");
        handleNextClick();
        getEventPaidPayments({ eventId: eventDetailsObj?.id, page: 1 })
            .then((res) => {
                setPaymentRecords((prevData) => {
                    return { ...prevData, paidData: res.data.data };
                });
                totalPaidRecords.current = res.data.meta.total; // Kept here only as paid tab will be shown on payments click
                per_page_records.current = res.data.meta.per_page;
            })
            .catch((err) => {
                console.log(err);
            });
        getEventReceivedPayments({ eventId: eventDetailsObj?.id, page: 1 })
            .then((res) => {
                setPaymentRecords((prevData) => {
                    return { ...prevData, receivedData: res.data.data };
                });
                totalReceivedRecords.current = res.data.meta.total;
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleCheckInRecordsClick = () => {
        setSlideType("eventCheckIns");
        handleNextClick();
        getEventTickets({ eventId: eventDetailsObj?.id })
            .then((res) => {
                if (res.data.data.length > 0) {
                    getCheckedInTickets({ eventId: eventDetailsObj?.id })
                        .then((res) => {
                            setCheckedInTickets(res.data);
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                } else {
                    getCheckInRecords({ eventId: eventDetailsObj?.id, page: 1 })
                        .then((res) => {
                            setCheckInRecords(res.data.data);
                            totalCheckInRecords.current = res.data.total;
                            per_page_records.current = res.data.per_page;
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const selfCheckInRecordsClick = (e) => {
        dispatch(
            update_event_self_checkin_field({ isSelfCheckIn: e.target.checked })
        );
        setCheckInCounter(e.target.checked);
    };

    const mySubmit = (data) => {

        setLoader(true);

        data.has_registration =
            eventTicketType === "paid" ? 1 : +isRegistration;
        data.is_paid = +(eventTicketType === "paid");
        data.is_private = +(eventTypeValue === "invite only");
        data.is_self_check_in = +isSelfCheckedIn;

        const payload = manageEventSettingsPayload(data, eventDetailsObj);
        updateEventDetails(payload, eventDetailsObj?.id)
            .then((res1) => {
                if ("bank_id" in payload) {
                    const bankPayload = {
                        event_id: eventDetailsObj?.id
                    };
                    createLinkBankAccount(payload["bank_id"], bankPayload)
                        .then((res2) => {
                            if (!res2.data.link_account_already_created) {
                                toast.success(res2.data.message, {
                                    position: "top-right",
                                    theme: "dark"
                                });
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                            toast.error(err?.response?.data.message, {
                                position: "top-right",
                                theme: "dark"
                            });
                        });
                }
                dispatch(update_event_details(res1.data.data));
                handleClose(null, null, "settings"); // Passed 2 null as there are no event and reason param for this case of dialog close
                setLoader(false);
                toast.success("Event settings changed!!", {
                    position: "top-right",
                    theme: "dark"
                });
            })
            .catch((err) => {
                console.log(err);
                setLoader(false);
                toast.error(Object.values(err.response.data)[0][0], {
                    position: "top-right",
                    theme: "dark"
                });
            });
    };

    const generateToken = () => {
        const newToken = `${eventDetailsObj.id}${Math.random().toString(36).substring(2, 18)}`; // Generates a token based on event ID and a random string
        setToken(newToken);
        const payload = { token: newToken };

        // Send the token to the API
        sendAPIToken(payload, eventDetailsObj?.id)
            .then(response => {
                dispatch(
                    update_event_api_token_field({ eventToken: newToken })
                );
                toast.success(response.data['message'], {
                    position: "top-right",
                    theme: "dark"
                });
                setOpenTokenModal(true);
            })
            .catch(error => {
                toast.error(error.data['message'], {
                    position: "top-right",
                    theme: "dark"
                });
                console.error('Error sending token:', error);
            });


    };

    const handleCloseTokenModal = () => {
        setOpenTokenModal(false);
    };

    const handleCopyToken = () => {
        if (eventDetailsObj.event_token) {
            navigator.clipboard.writeText(eventDetailsObj.event_token);
        } else {
            navigator.clipboard.writeText(token);
        }
        setShowTick(true);
        setTimeout(() => {
            setShowTick(false);
        }, 5000);
        toast.success("Token copied to clipboard!", {
            position: "top-right",
            theme: "dark"
        });
    };

    const handleEmailToggleChange = (e) => {
        setIsEmailEnabled(e.target.checked);
        if (!e.target.checked) {
            reset({
                event_email: "",
                sender_name: ""
            });
        } else {
            reset({
                event_email: eventDetailsObj?.event_mail_id,
                sender_name: eventDetailsObj?.sender_name
            });
        }
    };
    const handleSMSToggleChange = (e) => {
        setIsSendSMSEnabled(e.target.checked)
        if (!e.target.checked) {
            reset({
                event_sms_api_key: "",
                event_sms_sender_id: ""
            });
        } else {
            reset({
                event_sms_api_key: eventDetailsObj?.event_sms_api_key,
                event_sms_sender_id: eventDetailsObj?.event_sms_sender_id
            });
        }
    }


    const handleShowKeyField = (e) => {
        setKeyFieldShow(true);
    }

    const handleWhatsappKeyChange = (e) => {
        const value = e.target.value;
        setWhatsappFormData(prev => ({
            ...prev,
            whatsapp_key: value
        }));
        if (value) {
            setWhatsappFormErrors(prev => ({
                ...prev,
                whatsapp_key: ""
            }))
        }
    };
    const handleWhatsappFormPhoneChange = (e) => {
        const value = e.target.value
        setWhatsappFormData(prev => ({
            ...prev,
            phone_number_id: value
        }));
        if (value) {
            setWhatsappFormErrors(prev => ({
                ...prev,
                phone_number_id: ""
            }))
        }
    }

    const validateWhatsappForm = () => {
        const errors = {
            whatsapp_key: "",
            phone_number_id: "",
            templates: {}
        };
        let isValid = true;

        if (!whatsappFormData.whatsapp_key.trim()) {
            errors.whatsapp_key = 'Whatsapp key is required';
            isValid = false;
        }
        if (!whatsappFormData.phone_number_id.trim()) {
            errors.phone_number_id = 'phone number id is required';
            isValid = false;
        }

        whatsappFormData.templates.forEach((template, index) => {
            errors.templates[index] = {};

            if (!template.template_name.trim()) {
                errors.templates[index].name = 'Template name is required';
                isValid = false;
            }
            if (!template.template_id.trim()) {
                errors.templates[index].id = 'Template ID is required';
                isValid = false;
            }
        })

        setWhatsappFormErrors(errors);
        return isValid;
    };

    const handleWhatsappFormSubmit = () => {
        if (validateWhatsappForm()) {
            setValue("whatsapp_key", whatsappFormData.whatsapp_key);
            setValue("phone_number_id", whatsappFormData.phone_number_id)
            const transformedTemplate = whatsappFormData.templates.map(template => (
                {
                    template_id: template.template_id,
                    template_name: template.template_name,
                    display_name: template.display_name
                }
            ));
            setValue('templates', transformedTemplate);
            setKeyFieldShow(false);
        }
    };

    const handleTemplateChange = (index, field, value) => {
        setWhatsappFormData(prev => ({
            ...prev,
            templates: prev.templates.map((template, i) =>
                i === index
                    ? { ...template, [field]: value }
                    : template
            )
        }));

        if (value) {
            setWhatsappFormErrors(prev => ({
                ...prev,
                templates: {
                    ...prev.templates,
                    [index]: {
                        ...prev.templates[index],
                        [field]: ""
                    }
                }
            }));
        }
    };
    return (
        <>
            <form autoComplete="off" onSubmit={handleSubmit(mySubmit)}>

                <DialogContent
                    dividers={true}
                    sx={{
                        p: { xs: "1rem", md: "1rem 1.25rem" },
                        height: { xs: "26rem", md: "31rem" },
                        overflowY: "auto"
                    }}
                >

                    <Stack spacing={1.5}>
                        <Box>
                            <Typography sx={{ mb: 1 }}>Event type</Typography>
                            <CustomRadioGroup
                                value={eventTypeValue}
                                handleRadioChange={handleEventTypeRadioChange}
                                option1="public"
                                option2="invite only"
                            />
                        </Box>

                        <TicketTypeOptions
                            register={register}
                            eventTicketType={eventTicketType}
                            handleTicketTypeRadioChange={
                                handleTicketTypeRadioChange
                            }
                        />

                        <Box>
                            <Stack
                                direction="row"
                                alignItems="center"
                                justifyContent="space-between"
                                spacing={2}
                            >
                                <Typography sx={{ fontSize: { xs: "1rem" } }}>
                                    Event registration form required
                                </Typography>
                                <Switch
                                    color="warning"
                                    disabled={
                                        eventTicketType === "paid"
                                            ? true
                                            : false
                                    }
                                    checked={
                                        eventTicketType === "paid"
                                            ? true
                                            : isRegistration
                                    }
                                    onChange={(e) =>
                                        handleRegistrationChange(e)
                                    }
                                />
                            </Stack>
                            <Collapse
                                in={
                                    eventTicketType === "paid"
                                        ? true
                                        : isRegistration
                                }
                                unmountOnExit={true}
                            >
                                <Stack
                                    ref={refs?.formBtnRef}
                                    direction="row"
                                    alignItems="center"
                                    justifyContent="space-between"
                                    onClick={handleRegistrationClick}
                                    sx={{
                                        pt: 1.5,
                                        ":hover": {
                                            cursor: "pointer"
                                        }
                                    }}
                                >
                                    <Typography
                                        sx={{ fontSize: { xs: "1rem" } }}
                                    >
                                        Registration form
                                    </Typography>
                                    <Box sx={{ pr: 0.5 }}>
                                        <ArrowForwardIosIcon
                                            sx={{ color: "#1F274A" }}
                                        />
                                    </Box>
                                </Stack>
                            </Collapse>
                        </Box>

                        <Stack
                            ref={refs?.paymentsBtnRef}
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                            onClick={handlePaymentsClick}
                            sx={{
                                ":hover": {
                                    cursor: "pointer"
                                }
                            }}
                        >
                            <Typography sx={{ fontSize: { xs: "1rem" } }}>
                                Payments
                            </Typography>
                            <Box sx={{ pr: 0.5 }}>
                                <ArrowForwardIosIcon
                                    sx={{ color: "#1F274A" }}
                                />
                            </Box>
                        </Stack>

                        <Stack
                            ref={refs?.checkInsBtnRef}
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                            onClick={handleCheckInRecordsClick}
                            sx={{
                                ":hover": {
                                    cursor: "pointer"
                                }
                            }}
                        >
                            <Typography sx={{ fontSize: { xs: "1rem" } }}>
                                Check In Records
                            </Typography>
                            <Box sx={{ pr: 0.5 }}>
                                <ArrowForwardIosIcon
                                    sx={{ color: "#1F274A" }}
                                />
                            </Box>
                        </Stack>

                        <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                            spacing={2}
                        >
                            <Typography sx={{ fontSize: { xs: "1rem" } }}>
                                Self Check-in
                            </Typography>
                            <Switch
                                color="warning"
                                checked={isSelfCheckedIn}
                                onChange={(e) => selfCheckInRecordsClick(e)}
                            />
                        </Stack>
                        <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                            spacing={2}
                        >
                            <Typography sx={{ fontSize: { xs: '1rem' } }}>
                                Buy Multiple Tickets
                            </Typography>
                            <Switch
                                color='warning'
                                checked={isMultipleTickets}
                                onChange={(e) => handleMultipleTickets(e)}

                            />
                        </Stack>
                        {/* Not needed as of now */}
                        {/* <Stack direction='row' alignItems='center' justifyContent='space-between' spacing={2} >
                                <Typography sx={{ fontSize: { xs: '1rem' } }}>
                                    Will you like to add QR code for your guest verification
                                </Typography>
                                <Switch
                                    color='warning'
                                    defaultChecked={eventDetailsObj?.is_qrcode ? true : false}
                                    {...register("is_qrcode")}
                                />
                            </Stack> */}

                        <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                            spacing={2}
                        >
                            <Typography sx={{ fontSize: { xs: "1rem" } }}>
                                Add Sender Email ID
                            </Typography>
                            <Switch
                                color="warning"
                                checked={isEmailEnabled}
                                onChange={handleEmailToggleChange}
                            />
                        </Stack>
                        <Collapse
                            in={isEmailEnabled}
                            unmountOnExit={true}
                        >
                            <Box style={{
                                display: "flex",
                                flexDirection: isSmallDevices ? "column" : "row",
                                gap: isSmallDevices ? 0 : "10px"
                            }}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    label="Sender Email"
                                    type="email"
                                    defaultValue={eventDetailsObj?.event_mail_id}
                                    color="warning"
                                    error={errors.event_email}
                                    helperText={
                                        errors.event_email ? errors.event_email.message : ""
                                    }
                                    {...register("event_email")}
                                />
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    label="Sender Name"
                                    defaultValue={eventDetailsObj?.sender_name}
                                    color="warning"
                                    error={errors.sender_name}
                                    helperText={
                                        errors.sender_name ? errors.sender_name.message : ""
                                    }
                                    {...register("sender_name")}
                                />
                            </Box>
                        </Collapse>
                        {/* <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                            spacing={2}
                        >
                            <Typography sx={{ fontSize: { xs: "1rem" } }}>
                                Add Sender SMS ID
                            </Typography>
                            <Switch
                                color="warning"
                                checked={isSendSMSEnabled}
                                onChange={handleSMSToggleChange}
                            />
                        </Stack> */}
                        <Collapse
                            in={isSendSMSEnabled}
                            unmountOnExit={true}
                        >
                            <Box style={{
                                display: "flex",
                                flexDirection: isSmallDevices ? "column" : "row",
                                gap: isSmallDevices ? 0 : "10px"
                            }}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    label="API key"
                                    defaultValue={eventDetailsObj?.event_sms_api_key}
                                    color="warning"
                                    error={errors.event_sms_api_key}
                                    helperText={
                                        errors.event_sms_api_key ? errors.event_sms_api_key.message : ""
                                    }
                                    {...register("event_sms_api_key")}
                                />
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    label="Sender ID"
                                    defaultValue={eventDetailsObj?.event_sms_sender_id}
                                    color="warning"
                                    error={errors.event_sms_sender_id}
                                    helperText={
                                        errors.event_sms_sender_id ? errors.event_sms_sender_id.message : ""
                                    }
                                    {...register("event_sms_sender_id")}
                                />
                            </Box>
                        </Collapse>

                        <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                            spacing={2}
                        >
                            <Typography sx={{ fontSize: { xs: "1rem" } }}>
                                Add Your Whatsapp Number
                            </Typography>

                            <Button onClick={handleShowKeyField} sx={{ backgroundColor: "rgba(237, 108, 2, 0.04)", color: "#ed6c02" }}> Add</Button>
                        </Stack>
                        {eventDetailsObj.current_user_role === "owner" && (
                            <Stack
                                direction="row"
                                alignItems="center"
                                spacing={2}
                            >

                                <Typography sx={{ fontSize: { xs: "1rem" } }}>
                                    Your API token
                                </Typography>
                                {eventDetailsObj.event_token || token ? (
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "10px"
                                        }}
                                    >
                                        <TextField
                                            value={
                                                eventDetailsObj.event_token ||
                                                token
                                            }
                                            InputProps={{
                                                readOnly: true,
                                                classes: {
                                                    input: "blurred-input"
                                                }
                                            }}
                                            sx={{ ml: 2, width: "45%" }}
                                        />
                                        {showTick ? (
                                            <CheckCircleRoundedIcon
                                                style={{ color: "green" }}
                                            />
                                        ) : (
                                            <IconButton
                                                onClick={handleCopyToken}
                                            >
                                                <ContentCopyIcon
                                                    style={{ padding: "0px" }}
                                                />
                                            </IconButton>
                                        )}
                                    </Box>
                                ) : (
                                    <LoadingButton
                                        onClick={generateToken}
                                        color="primary"
                                        variant="contained"
                                        sx={{ ml: 4 }}
                                    >
                                        Generate API token
                                    </LoadingButton>
                                )}
                            </Stack>
                        )}
                    </Stack>
                </DialogContent>

                <DialogActions sx={{ mr: 1, my: 0.5 }}>
                    <LoadingButton
                        type="submit"
                        color="warning"
                        variant="contained"
                        loading={loader ? true : false}
                        startIcon={loader ? <SaveIcon /> : null}
                        loadingPosition={loader ? "start" : null}
                    >
                        {loader ? "Saving..." : "Save"}
                    </LoadingButton>
                </DialogActions>
            </form>
            <TokenDialog
                open={openTokenModal}
                onClose={handleCloseTokenModal}
                token={token}
                handleCopyToken={handleCopyToken}
                showTick={showTick}
            />
            <Dialog
                open={keyFieldShow}
                PaperProps={{
                    sx: {
                        width: { xs: '90%', md: '60%' },
                        maxWidth: { lg: '700px' },
                        m: { xs: 0 }
                    }
                }}
                onClose={() => setKeyFieldShow(false)}
            >
                <Box sx={{ padding: "20px" }}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        label="Whatsapp Key"
                        value={whatsappFormData.whatsapp_key}
                        onChange={handleWhatsappKeyChange}
                        error={!!whatsappFormErrors.whatsapp_key}
                        helperText={whatsappFormErrors.whatsapp_key}
                        color="warning"
                    />

                    <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        label="Phone Number Id"
                        value={whatsappFormData.phone_number_id}
                        onChange={handleWhatsappFormPhoneChange}
                        error={!!whatsappFormErrors.phone_number_id}
                        helperText={whatsappFormErrors.phone_number_id}
                        color="warning"
                    />

                    {whatsappFormData.templates.map((template, index) => (
                        <Box
                            key={template.key}
                            sx={{
                                mt: 2,
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 1,
                                position: 'relative',
                            }}
                        >


                            <Typography >{template?.display_name} Template</Typography>

                            <TextField
                                variant="outlined"
                                fullWidth
                                defaultValue={template.template_id}
                                onChange={(e) => handleTemplateChange(index, 'template_id', e.target.value.replace(/\D/g, ''))}
                                error={!!whatsappFormErrors.templates[index]?.id}
                                helperText={whatsappFormErrors.templates[index]?.id}
                                color="warning"
                                placeholder={"Template ID"}
                            />

                        </Box>

                    ))}



                    <Button
                        onClick={handleWhatsappFormSubmit}
                        sx={{
                            width: "100%",
                            backgroundColor: "#ed6c02",
                            color: "white",
                            mt: 3,
                            '&:hover': {
                                backgroundColor: "#c65602"
                            }
                        }}
                    >
                        Save
                    </Button>
                </Box>
            </Dialog>
        </>
    );
};

export default SettingsForm;
