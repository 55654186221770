import {
    Box,
    Button,
    MobileStepper,
    Stack,
    Typography,
    useTheme
} from "@mui/material";
import React from "react";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { Navigate } from "react-router-dom";

const MobileInvitationStepper = ({
    steps,
    activeStep,
    getStepContent,
    handleNext,
    handleBack,
    paymentDone
}) => {
    const theme = useTheme();
    return (
        <>
            <Stack alignItems="center" justifyContent="center">
                <Typography
                    sx={{ fontWeight: 600, fontSize: "1.25rem", py: 1 }}
                >
                    {steps[activeStep]}
                </Typography>
            </Stack>
            <Box sx={{ width: "100%" }}>{getStepContent(activeStep)}</Box>
            <MobileStepper
                variant="dots"
                steps={steps.length}
                activeStep={activeStep}
                sx={{
                    backgroundColor: "#F2F2F2",
                    ".MuiMobileStepper-dotActive": {
                        backgroundColor: "#FF8359 !important"
                    }
                }}
                nextButton={
                    activeStep === steps.length ? (
                        <>
                            <Navigate to="/dashboard" />
                        </>
                    ) : (
                        <>
                            <Button
                                color="warning"
                                size="small"
                                onClick={handleNext}
                                disabled={activeStep === steps.length - 1}
                            >
                                Next
                                {theme.direction === "rtl" ? (
                                    <KeyboardArrowLeft />
                                ) : (
                                    <KeyboardArrowRight />
                                )}
                            </Button>
                        </>
                    )
                }
                backButton={
                    <Button
                        size="small"
                        color="warning"
                        onClick={handleBack}
                        disabled={
                            activeStep === 0 ||
                            (activeStep === 1 && paymentDone)
                        }
                    >
                        {theme.direction === "rtl" ? (
                            <KeyboardArrowRight />
                        ) : (
                            <KeyboardArrowLeft />
                        )}
                        Back
                    </Button>
                }
            />
        </>
    );
};

export default MobileInvitationStepper;
