import { Box, TextField, Typography } from "@mui/material";
import React from "react";

const AddQuantity = ({ availablePasses, register, errors, setValue }) => {
    return (
        <Box
            sx={{
                mt: 3,
                display: "flex",
                flexDirection: "column",
                alignItems: "center"
            }}
        >
            <Box
                sx={{
                    display: "grid",
                    gap: 1
                }}
            >
                <TextField
                    id="passQuantity"
                    name="passQuantity"
                    label="Number of Passes"
                    size="small"
                    {...register("passQuantity", {
                        valueAsNumber: true,
                        onChange: (e) => {
                            const value = e.target.value
                                ? parseInt(e.target.value)
                                : "";
                            setValue("passQuantity", value, {
                                shouldValidate: true
                            });
                        }
                    })}
                    onInput={(e) => {
                        // Restrict input to numeric values only
                        e.target.value = e.target.value.replace(/\D/g, "");
                    }}
                    error={!!errors.passQuantity}
                    helperText={errors?.passQuantity?.message}
                    sx={{
                        "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                                borderColor: "#FF8359"
                            },
                            "&:hover fieldset": {
                                borderColor: "#FF8359"
                            },
                            "&.Mui-focused fieldset": {
                                borderColor: "#FF8359"
                            }
                        },
                        "& .MuiInputLabel-root": {
                            color: "#FF8359"
                        },
                        "& .MuiInputLabel-root.Mui-focused": {
                            color: "#FF8359"
                        },
                        "& .MuiInputLabel-root.Mui-error": {
                            color: "#FF8359"
                        }
                    }}
                />
                <Typography variant="body2" color="textSecondary">
                    Number of available passes: {availablePasses}
                </Typography>
            </Box>
        </Box>
    );
};

export default AddQuantity;
