import React, { useEffect, useMemo, useState } from "react";
import { SendRounded } from "@mui/icons-material";
import Button from "@mui/material/Button";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    useTheme,
    useMediaQuery,
    Grid,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
    Stack,
    Skeleton
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Papa from "papaparse";
import { useSelector } from "react-redux";
import { eventDetails } from "../../../../../../../../../../redux/slices/eventDataSlice";
import { getInvitationMessageTemplates } from "../../../../../../../../../../services/wEvents/ticketTemplates";
import { loadScript } from "../../../../../../../../../../utils/functions/loadScript";
import { parseDecimalNumber } from "../../../../../../../../../../utils/functions/getFormattedNumber";
import { ticketCurrencies } from "../../../../../../../../../../utils/constants/ticketCurrency";
import { createOrder } from "../../../../../../../../../../services/Payment/paymentGateway";
import { userDetails } from "../../../../../../../../../../redux/slices/userDataSlice";
import logo from "../../../../../../../../../../assets/WowslyLogo.png";
import { razorPayModalConfig } from "../../../../../../../../../../utils/constants/razorPayModalConfig";
import getGeneratedOrSendTicketCharges from "../../../../../../../../../../utils/functions/getGeneratedOrSendTicketCharges";
import _, { capitalize } from "lodash";
import MobileInvitationStepper from "../MobileInvitationStepper";
import WebInvitationStepper from "../WebInvitationStepper";
import SetUp from "./Steps/SetUp";
import PurchaseOrder from "./Steps/PurchaseOrder";
import SendTickets from "./Steps/SendTickets";
import { isAdminUser } from "../../../../../../../../../../utils/functions/isAdminUser";
import sendTicketGuestsWithFacilityCsv from "../../../../../../../../../../assets/csvfile/Send Passes Format Wowsly With Facility.csv";
import sendTicketGuestsWithoutFacilityCsv from "../../../../../../../../../../assets/csvfile/Send Passes Format Wowsly Without Facility.csv";
import { FileUploader } from "react-drag-drop-files";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import InfoRounded from "@mui/icons-material/InfoRounded";
import SaveIcon from "@mui/icons-material/Save";
import { LoadingButton } from "@mui/lab";
import { sendQRPassesForEvent } from "../../../../../../../../../../services/wEvents/eventGuests";

const SendTicketsDialog = ({ ticket }) => {
    const theme = useTheme();
    const eventData = useSelector(eventDetails);

    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [loadingPayment, setLoadingPayment] = useState(false);
    const [file, setFile] = useState(null);
    const [messageTemplates, setMessageTemplates] = useState(null);
    const [fileInputError, setFileInputError] = useState(null);
    const razorPayCurrency = ticketCurrencies.find(
        (t) => t.value === "rupees"
    )?.currency;
    const userData = useSelector(userDetails);
    const isUserAdmin = isAdminUser(userData?.mobile);
    const [paymentDone, setPayment] = useState(false);
    const [totalGuests, setTotalGuests] = useState(0);
    const [totalRows, setTotalRows] = useState(0);

    // included facility count multiplied by total guests
    const [totalIncludedFacilityCount, setTotalIncludedFacilityCount] =
        useState(0);
    // additional facility count specified in facilities column (in csv) for each guest
    const [additionalFacilityCount, setAdditionalFacilityCount] = useState(0);
    const [sending, setSending] = useState(false);
    const isMobileScreen = useMediaQuery("(max-width:600px)");
    const [sendQRTicketsActiveStep, setSendQRTicketsActiveStep] = useState(0);
    const steps = ["Set Up", "Make Payment", "Send Tickets"];

    const [isCloseConfirmationDialogOpen, setIsCloseConfirmationDialogOpen] =
        useState(false);

    const GST_PERCENT = process.env.REACT_APP_GST_PERCENT_FOR_SEND_MESSAGE;

    const closeDialogConfirmation = () => {
        setIsCloseConfirmationDialogOpen(true);
    };

    const handleCloseDialog = (
        event,
        reason,
        openCloseConfirmationDialog = true // this will be false when download button is clicked
    ) => {
        if (reason && reason === "backdropClick") return;
        if (!isUserAdmin) {
            // if the payment is done and event owner attempts to close dialog box then show warning message
            if (
                ((sendQRTicketsActiveStep === 1 && paymentDone) ||
                    sendQRTicketsActiveStep === 2) &&
                !isCloseConfirmationDialogOpen &&
                openCloseConfirmationDialog
            ) {
                closeDialogConfirmation();
                return;
            }
            setFileInputError(null);
            document.body.classList.remove("no-scroll");
            setMessageTemplates(null);
            setPayment(false);
            setSendQRTicketsActiveStep(0);
            setIsCloseConfirmationDialogOpen(false);
            setSending(false);
        }
        setFile(null);
        reset();
        setIsDialogOpen(false);
    };

    const schema = yup.object({});

    const availablePasses = useMemo(() => {
        return ticket?.quantity - ticket?.sold_out;
    }, [ticket]);

    const {
        handleSubmit,
        register,
        setValue,
        getValues,
        control,
        watch,
        reset,
        formState: { errors }
    } = useForm({
        defaultValues: {
            guestList: null,
            organiserName: eventData?.owner.full_name || "",
            eventName: eventData?.title,
            date: eventData?.start_date,
            time: eventData?.start_time,
            venue: eventData?.address
        },
        resolver: yupResolver(schema)
    });

    const templateId = watch("templateId");

    const handleNext = () => {
        if (sendQRTicketsActiveStep === 0 && !file) {
            setFileInputError("Please select a file");
            return;
        }
        if (sendQRTicketsActiveStep === 1 && !paymentDone) {
            toast.error("Please make payment first!!", {
                position: "top-right",
                theme: "dark"
            });
            return;
        }
        setSendQRTicketsActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setSendQRTicketsActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const calculateTotals = (data) => {
        let totalGuest = 0;
        let totalRows = 0;
        let includedFacilitiesCount = 0;
        let additionalFacilitiesCount = 0;
        if (ticket && ticket.facilities) {
            for (let i = 0; i < ticket.facilities.length; i++) {
                if (ticket.facilities[i].is_included === 1) {
                    includedFacilitiesCount += 1;
                }
            }
        }

        if (data.length > 0) {
            for (let i = 0; i < data.length; i++) {
                // check if contact exists and not null
                if (
                    "CountryCode" in data[i] &&
                    "MobileNumber" in data[i] &&
                    data[i]["MobileNumber"] !== "" &&
                    data[i]["CountryCode"] !== ""
                ) {
                    totalRows += 1;
                    const value = parseInt(data[i]["GuestsAllowedPerTicket"]);
                    if (!isNaN(value)) {
                        // if value is specified in csv file take it
                        totalGuest += value;
                    } else if (data[i]["GuestsAllowedPerTicket"] === "") {
                        // if value is not specified in csv file then consider 1
                        totalGuest += 1;
                    }
                }
                if (data[i]["Facilities"]) {
                    totalGuest = totalRows;
                    let facilitiesFromData;
                    try {
                        // Replace curly quotes with straight quotes
                        let facilitiesString = data[i]["Facilities"]
                            .replace(/“/g, '"')
                            .replace(/”/g, '"')
                            .replace(/‘/g, '"')
                            .replace(/’/g, '"');
                        facilitiesFromData = JSON.parse(facilitiesString);
                    } catch (e) {
                        console.error(
                            "Failed to parse facilities from data:",
                            data[i]["Facilities"]
                        );
                        continue;
                    }
                    if (ticket && ticket.facilities) {
                        for (let j = 0; j < ticket.facilities.length; j++) {
                            let facilityNameFromTicket = ticket.facilities[
                                j
                            ].name
                                .trim()
                                .toLowerCase();
                            // Match with facilities from data
                            for (let key in facilitiesFromData) {
                                let facilityNameFromData = key
                                    .trim()
                                    .toLowerCase();
                                if (
                                    facilityNameFromTicket ===
                                        facilityNameFromData &&
                                    facilitiesFromData[key]
                                        .trim()
                                        .toLowerCase() === "yes" &&
                                    ticket.facilities[j].is_included !== 1
                                ) {
                                    additionalFacilitiesCount += 1;
                                }
                            }
                        }
                    }
                }
            }
        }
        setTotalIncludedFacilityCount(includedFacilitiesCount * totalRows);
        setAdditionalFacilityCount(additionalFacilitiesCount);
        setTotalGuests(totalGuest);
        setTotalRows(totalRows);
    };

    const parseCSVFile = (file) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            // onload event is triggered once file contents are read
            const csvText = e.target.result;
            const { data } = Papa.parse(csvText, { header: true }); // parse csv text to array of objects
            calculateTotals(data);
        };
        reader.readAsText(file); // reads content of file as text
    };

    const handleFileChange = (e) => {
        setFileInputError(null);
        if (e.type !== "text/csv") {
            toast.error("Only CSV file allowed", {
                position: "top-right",
                theme: "dark"
            });
            return;
        }
        if (e) {
            setFile(e);
            setValue("guestList", e);
            parseCSVFile(e);
        }
    };

    let subTotal = getGeneratedOrSendTicketCharges(totalGuests) * totalGuests;
    let facilitiesCharges =
        (totalIncludedFacilityCount + additionalFacilityCount) * 1; // apply 1 RS charge for each facilities
    let emailAndWhatsappCharges = 0.99 * totalRows;
    subTotal += emailAndWhatsappCharges; // apply whatsapp and email charges for each row
    subTotal += facilitiesCharges; // applly facilitiesCharges
    let gstCharge = (GST_PERCENT / 100) * subTotal;
    gstCharge = parseDecimalNumber(gstCharge, 2);
    const totalAmountToPay = subTotal + gstCharge;

    // if the user is admin send QR passes else perform this function in 3rd step
    const sendQRPasses = (values) => {
        setSending(true);
        if (!values.guestList) {
            setSending(false);
            toast.error("Something went wrong!!", {
                position: "top-right",
                theme: "dark"
            });
            return;
        }
        const formData = new FormData();
        formData.append("guest_list", values.guestList);
        formData.append("organiser_name", values.organiserName);
        formData.append("template_id", values.templateId);
        const templateData = {};
        for (const field of templateFields) {
            templateData[field[0]] = values[field[0]];
        }
        formData.append("template_data", JSON.stringify(templateData));
        sendQRPassesForEvent({
            eventId: eventData?.id,
            ticketId: ticket?.id,
            payload: formData
        })
            .then((res) => {
                setPayment(true);
                setSending(false);
                // if (res.data.success) {
                //     const ticketIdx = tickets.findIndex(t => t.id === ticket?.id);
                //     if (ticketIdx !== -1) {
                //         const updatedTickets = [...tickets];
                //         updatedTickets[ticketIdx].sold_out += res.data.total_sent;
                //         setTickets(updatedTickets);
                //     }
                // }
                toast.success(`QR passes will be sent to users`, {
                    position: "top-right",
                    theme: "dark"
                });
                handleCloseDialog();
            })
            .catch((err) => {
                setSending(false);
                console.log(err);
                toast.error(Object.values(err.response.data)[0][0], {
                    position: "top-right",
                    theme: "dark"
                });
            });
    };

    const onSubmit = async (values) => {
        setLoadingPayment(true);
        if (!values.guestList) {
            setLoadingPayment(false);
            toast.error("Something went wrong!!", {
                position: "top-right",
                theme: "dark"
            });
            return;
        } else {
            const res = await loadScript(
                "https://checkout.razorpay.com/v1/checkout.js"
            );
            if (!res) {
                alert(
                    "Failed to load Razorpay payment gateway. Please try again later."
                );
                return;
            }

            const razorPayPayload = {
                payment_amount: totalAmountToPay * 100, // For rupees send in paisa
                amount_currency: razorPayCurrency,
                receipt: "Wowsly_Send_Tickets"
            };

            createOrder(razorPayPayload)
                .then((result) => {
                    console.log(result);
                    const order_id = result.data?.id;
                    const options = {
                        key: process.env.REACT_APP_RAZOR_PAY_LIVE_KEY,
                        amount: totalAmountToPay * 100, // Amount in paise
                        currency: razorPayCurrency,
                        name: "Send Pass Payment",
                        description: "Payment for sending QR passes",
                        notes: {
                            task: "generate_or_send_qr_passes",
                            user_id: userData?.id,
                            ticket_id: ticket.id,
                            currency: razorPayCurrency,
                            gst_percent:
                                process.env
                                    .REACT_APP_GST_PERCENT_FOR_SEND_MESSAGE,
                            gst_charges: gstCharge,
                            total_passes: totalGuests,
                            type: "Send",
                            whatsapp_and_email_charges: emailAndWhatsappCharges
                        },
                        image: logo,
                        order_id: order_id,
                        handler: function (response) {
                            console.log("Done", response);
                            if (
                                "razorpay_payment_id" in response &&
                                "razorpay_order_id" in response &&
                                "razorpay_signature" in response
                            ) {
                                setLoadingPayment(false);
                                setPayment(true);
                            } else {
                                setLoadingPayment(false);
                                toast.error(
                                    "Payment failed due to some issues, please try again later!!",
                                    {
                                        position: "top-right",
                                        theme: "dark"
                                    }
                                );
                                return;
                            }
                        },
                        modal: {
                            ondismiss: function () {
                                setLoadingPayment(false);
                            }
                        },
                        prefill: {
                            name: userData?.full_name,
                            email: userData?.email ?? null,
                            contact: userData?.mobile
                        },
                        theme: {
                            color: theme.palette.primaryColor
                        },
                        config: razorPayModalConfig.paymentMethodsConfiguration
                    };
                    const razorpay = new window.Razorpay(options);
                    razorpay.open();
                    razorpay.on("payment.failed", function (response) {
                        setLoadingPayment(false);
                        console.log(response);
                        toast.error(response.error.description, {
                            position: "top-right",
                            theme: "dark"
                        });
                    });
                })
                .catch((err) => {
                    console.log(err);
                    setLoadingPayment(false);
                    toast.error(err.response.data.error, {
                        position: "top-right",
                        theme: "dark"
                    });
                });
        }
    };

    const templateFields = useMemo(() => {
        if (messageTemplates) {
            const fields = JSON.parse(
                messageTemplates.find((t) => t.id === templateId)?.fields ||
                    "{}"
            );
            if (!_.isEmpty(fields)) {
                return Object.entries(fields).sort(
                    ([, a], [, b]) => a.order - b.order
                );
            }
        }
        return [];
    }, [templateId, messageTemplates]);

    const messagePreviewText = useMemo(() => {
        if (messageTemplates) {
            const template = messageTemplates.find((t) => t.id === templateId);
            if (!template) return "";
            const values = getValues();
            return template.template_string.replace(
                /\{\{(.+?)\}\}/g,
                (matching, value) => {
                    return values[value.trim()]
                        ? values[value.trim()]
                        : matching;
                }
            );
        }
    }, [templateId, watch()]);

    useEffect(() => {
        if (isDialogOpen) {
            getInvitationMessageTemplates(ticket.event_id)
                .then((res) => {
                    if (res.data.success) {
                        setMessageTemplates(res.data.data);
                        setValue("templateId", 1);
                    }
                })
                .catch((err) => {
                    console.log(err);
                    toast.error(Object.values(err.response.data)[0][0], {
                        position: "top-right",
                        theme: "dark"
                    });
                });
        }
    }, [isDialogOpen]);

    const getStepContent = (stepKey) => {
        switch (stepKey) {
            case 0:
                return (
                    <SetUp
                        availablePasses={availablePasses}
                        register={register}
                        errors={errors}
                        control={control}
                        watch={watch}
                        getValues={getValues}
                        facilityCount={ticket.facilities.length}
                        templateId={templateId}
                        messageTemplates={messageTemplates}
                        templateFields={templateFields}
                        handleFileChange={handleFileChange}
                        file={file}
                        fileInputError={fileInputError}
                    />
                );
            case 1:
                return (
                    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
                        <PurchaseOrder
                            totalGuests={totalGuests}
                            subTotal={subTotal}
                            totalIncludedFacilityCount={
                                totalIncludedFacilityCount
                            }
                            additionalFacilityCount={additionalFacilityCount}
                            ticketFacilityCount={ticket.facilities.length}
                            facilitiesCharges={facilitiesCharges}
                            emailAndWhatsappCharges={emailAndWhatsappCharges}
                            gstCharge={gstCharge}
                            totalRows={totalRows}
                            totalAmountToPay={totalAmountToPay}
                            loading={loadingPayment}
                            paymentDone={paymentDone}
                        />
                    </Box>
                );
            case 2:
                let payload = {};
                payload.guestList = watch("guestList");
                payload.templateId = templateId;
                payload.organiserName = watch("organiserName");
                payload.eventName = watch("eventName");
                return (
                    <SendTickets
                        ticket={ticket}
                        sending={sending}
                        setSending={setSending}
                        templateFields={templateFields}
                        payload={payload}
                        handleClose={handleCloseDialog}
                    />
                );
            default:
                return <></>;
        }
    };

    // if the user is admin then they can directly send tickets else user needs to complete stepper function
    return (
        <>
            <Button
                variant="outlined"
                color="warning"
                size="small"
                startIcon={<SendRounded />}
                onClick={() => setIsDialogOpen(true)}
                sx={{ borderColor: "#FF8359", color: "#FF8359" }}
            >
                Send Tickets
            </Button>

            {isUserAdmin ? (
                <>
                    <Dialog
                        fullWidth
                        open={isDialogOpen}
                        onClose={handleCloseDialog}
                        maxWidth="sm"
                    >
                        <DialogTitle sx={{ p: "0.5rem 1rem" }}>
                            <IconButton
                                onClick={() => handleCloseDialog()}
                                sx={{
                                    position: "absolute",
                                    right: "0.2rem",
                                    top: "0.2rem"
                                }}
                            >
                                <CloseIcon fontSize="medium" />
                            </IconButton>
                            <Typography
                                sx={{
                                    fontSize: { xs: "1.5rem", sm: "1.5rem" }
                                }}
                            >
                                Send Tickets
                            </Typography>
                        </DialogTitle>
                        <form
                            autoComplete="off"
                            onSubmit={handleSubmit(sendQRPasses)}
                        >
                            <DialogContent dividers={true}>
                                <Box pb={2}>
                                    <Typography>
                                        Please select the csv of guests
                                    </Typography>
                                    {/* if there are facilities only 1 guest allowed per ticket so different csv file formats */}
                                    <Button
                                        fullWidth
                                        href={
                                            ticket.facilities.length > 0
                                                ? sendTicketGuestsWithFacilityCsv
                                                : sendTicketGuestsWithoutFacilityCsv
                                        }
                                        download="Guests List.csv"
                                        color="warning"
                                        variant="outlined"
                                        sx={{ color: "#FF8359", my: 2, p: 2 }}
                                    >
                                        Download Sample Csv File
                                    </Button>
                                    <Divider />
                                    <Box sx={{ mt: 2 }}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <Typography
                                                    sx={{
                                                        fontWeight: "500",
                                                        fontSize: "15px",
                                                        display: "inline-block"
                                                    }}
                                                >
                                                    Available:
                                                </Typography>
                                                <Typography
                                                    sx={{
                                                        ml: 1,
                                                        color: "#6F738B",
                                                        display: "inline-block"
                                                    }}
                                                >
                                                    {availablePasses}
                                                </Typography>
                                            </Grid>
                                            {messageTemplates ? (
                                                <>
                                                    <Grid item xs={12}>
                                                        <FormControl
                                                            fullWidth
                                                            color="warning"
                                                        >
                                                            <InputLabel>
                                                                Select Message
                                                                Template
                                                            </InputLabel>
                                                            <Controller
                                                                name="templateId"
                                                                control={
                                                                    control
                                                                }
                                                                render={({
                                                                    field: {
                                                                        onChange,
                                                                        value
                                                                    }
                                                                }) => (
                                                                    <Select
                                                                        fullWidth
                                                                        label="Select Message Template"
                                                                        value={
                                                                            value
                                                                        }
                                                                        onChange={
                                                                            onChange
                                                                        }
                                                                    >
                                                                        {messageTemplates.map(
                                                                            (
                                                                                o,
                                                                                idx
                                                                            ) => (
                                                                                <MenuItem
                                                                                    key={`template_${idx}`}
                                                                                    value={
                                                                                        o.id
                                                                                    }
                                                                                >
                                                                                    {capitalize(
                                                                                        o.dotpe_template_id.replaceAll(
                                                                                            "_",
                                                                                            " "
                                                                                        )
                                                                                    )}
                                                                                </MenuItem>
                                                                            )
                                                                        )}
                                                                    </Select>
                                                                )}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                    {templateFields.map(
                                                        (fieldName) => (
                                                            <React.Fragment
                                                                key={
                                                                    fieldName[0]
                                                                }
                                                            >
                                                                {fieldName[1]
                                                                    .isEditable ? (
                                                                    <Grid
                                                                        item
                                                                        xs={12}
                                                                    >
                                                                        <TextField
                                                                            required
                                                                            fullWidth
                                                                            color="warning"
                                                                            label={capitalize(
                                                                                fieldName[0]
                                                                            )}
                                                                            error={
                                                                                errors?.[
                                                                                    fieldName
                                                                                ]
                                                                                    ? true
                                                                                    : false
                                                                            }
                                                                            helperText={
                                                                                errors[
                                                                                    fieldName[0]
                                                                                ]
                                                                                    ? errors[
                                                                                          fieldName[0]
                                                                                      ]
                                                                                          .message
                                                                                    : null
                                                                            }
                                                                            {...register(
                                                                                fieldName[0]
                                                                            )}
                                                                        />
                                                                    </Grid>
                                                                ) : null}
                                                            </React.Fragment>
                                                        )
                                                    )}
                                                    <Grid item xs={12}>
                                                        <Divider
                                                            sx={{ my: 2 }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            multiline
                                                            fullWidth
                                                            color="warning"
                                                            label="Message Preview"
                                                            value={
                                                                messagePreviewText
                                                            }
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}></Grid>
                                                    <Grid item xs={12}>
                                                        <FileUploader
                                                            name="guestList"
                                                            handleChange={
                                                                handleFileChange
                                                            }
                                                            types={["csv"]}
                                                            classes="group-upload-csv-drag-drop-area"
                                                            required={!file}
                                                            maxSize={5}
                                                            children={
                                                                <Stack
                                                                    spacing={1}
                                                                    alignItems="center"
                                                                    justifyContent="center"
                                                                    sx={{
                                                                        height: "100%",
                                                                        borderRadius:
                                                                            "0.5rem",
                                                                        border: `3.5px dashed ${theme.palette.primaryColor}`,
                                                                        ":hover":
                                                                            {
                                                                                cursor: "pointer"
                                                                            }
                                                                    }}
                                                                >
                                                                    <DriveFolderUploadIcon
                                                                        sx={{
                                                                            fontSize:
                                                                                "2rem",
                                                                            color: theme
                                                                                .palette
                                                                                .primaryColor
                                                                        }}
                                                                    />
                                                                    <Stack
                                                                        spacing={
                                                                            1
                                                                        }
                                                                        direction="row"
                                                                        justifyContent="center"
                                                                        alignItems="center"
                                                                    >
                                                                        {file ? (
                                                                            <CheckCircleIcon color="success" />
                                                                        ) : null}
                                                                        <Typography
                                                                            sx={{
                                                                                color: "#6F738B",
                                                                                fontSize:
                                                                                    "0.9rem"
                                                                            }}
                                                                        >
                                                                            {file
                                                                                ? "Uploaded Successfully"
                                                                                : "Drop or Upload Guest List CSV File"}
                                                                        </Typography>
                                                                    </Stack>

                                                                    {file ? (
                                                                        <Typography
                                                                            sx={{
                                                                                color: "#6F738B",
                                                                                fontSize:
                                                                                    "0.9rem"
                                                                            }}
                                                                        >
                                                                            File
                                                                            name:{" "}
                                                                            {
                                                                                file.name
                                                                            }
                                                                        </Typography>
                                                                    ) : null}
                                                                </Stack>
                                                            }
                                                            onSizeError={() => {
                                                                toast.error(
                                                                    "Maximum upload size for file is 5MB",
                                                                    {
                                                                        position:
                                                                            "top-right",
                                                                        theme: "dark"
                                                                    }
                                                                );
                                                            }}
                                                            dropMessageStyle={{
                                                                backgroundColor:
                                                                    "red"
                                                            }}
                                                        />
                                                        <Box
                                                            display="flex"
                                                            justifyContent="flex-start"
                                                            alignItems="flex-start"
                                                            gap={0.5}
                                                            mt={0.5}
                                                        >
                                                            <InfoRounded fontSize="0.75rem"></InfoRounded>
                                                            <Typography variant="caption">
                                                                Please keep in
                                                                mind that only
                                                                available
                                                                numbers of
                                                                tickets will be
                                                                sent. Rest
                                                                entries in CSV
                                                                will be ignored.
                                                            </Typography>
                                                        </Box>
                                                    </Grid>
                                                </>
                                            ) : (
                                                <Grid item xs={12}>
                                                    <Skeleton
                                                        animation="wave"
                                                        height="25.5rem"
                                                        sx={{ flex: 1 }}
                                                    ></Skeleton>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Box>
                                </Box>
                            </DialogContent>
                            <DialogActions sx={{ mr: 1, my: 0.5 }}>
                                <LoadingButton
                                    type="submit"
                                    variant="contained"
                                    color="warning"
                                    loading={sending}
                                    disabled={sending || availablePasses === 0}
                                    loadingPosition={sending ? "start" : null}
                                    startIcon={sending ? <SaveIcon /> : null}
                                >
                                    Generate & Send
                                </LoadingButton>
                            </DialogActions>
                        </form>
                    </Dialog>
                </>
            ) : (
                <>
                    <Dialog
                        open={isDialogOpen}
                        fullWidth
                        maxWidth="sm"
                        onClose={handleCloseDialog}
                        disableEscapeKeyDown
                    >
                        <DialogTitle sx={{ p: "0.5rem 1rem" }}>
                            <IconButton
                                onClick={handleCloseDialog}
                                sx={{
                                    position: "absolute",
                                    right: "0.2rem",
                                    top: "0.2rem"
                                }}
                            >
                                <CloseIcon fontSize="medium" />
                            </IconButton>
                            <Typography
                                sx={{
                                    fontSize: { xs: "1.5rem", sm: "1.5rem" }
                                }}
                            >
                                Send Tickets Process
                            </Typography>
                        </DialogTitle>
                        <Divider sx={{ mb: 1 }} />
                        <DialogContent
                            sx={{
                                px: "1rem",
                                py: "0rem",
                                marginBottom:
                                    isMobileScreen &&
                                    sendQRTicketsActiveStep === 0
                                        ? "20px"
                                        : "auto"
                            }}
                        >
                            {isMobileScreen ? (
                                <>
                                    <MobileInvitationStepper
                                        steps={steps}
                                        activeStep={sendQRTicketsActiveStep}
                                        getStepContent={getStepContent}
                                        handleBack={handleBack}
                                        handleNext={handleNext}
                                        paymentDone={paymentDone}
                                    />
                                </>
                            ) : (
                                <>
                                    <WebInvitationStepper
                                        steps={steps}
                                        activeStep={sendQRTicketsActiveStep} //edit to activeStep here
                                        getStepContent={getStepContent}
                                        handleBack={handleBack}
                                        handleNext={handleNext}
                                        paymentDone={paymentDone}
                                    />
                                </>
                            )}
                        </DialogContent>
                    </Dialog>

                    {isCloseConfirmationDialogOpen ? (
                        <Dialog
                            fullWidth
                            open={true}
                            onClose={() =>
                                setIsCloseConfirmationDialogOpen(false)
                            }
                            PaperProps={{
                                sx: {
                                    width: { xs: "80%", sm: "70%", md: "50%" },
                                    m: { xs: 0 }
                                }
                            }}
                        >
                            <DialogContent sx={{ p: '1.5rem 2rem' }}>
                                <Stack direction='row' alignItems="center" spacing={3}>
                                    <WarningAmberRoundedIcon color="warning" sx={{ marginRight: 1, fontSize: '4.5rem' }} />
                                    <Stack direction='column' spacing={1}>
                                        <Typography variant="h3">Are you sure?</Typography>
                                        <Typography variant="body2">
                                            If you leave this page, you will have to make the payment again to Send Tickets.
                                            Do you still want to exit?
                                        </Typography>
                                    </Stack>
                                </Stack>
                            </DialogContent>
                            <Divider />    
                            <DialogActions sx={{ mr: 1, my: 0.5 }}>
                                <Button
                                    color="warning"
                                    variant="outlined"
                                    onClick={() => {
                                        handleCloseDialog(false);
                                    }}
                                >
                                    Exit
                                </Button>
                                <Button
                                    variant="contained"
                                    color="warning"
                                    onClick={() =>
                                        setIsCloseConfirmationDialogOpen(false)
                                    }
                                >
                                    Stay
                                </Button>
                            </DialogActions>
                        </Dialog>
                    ) : null}
                </>
            )}
        </>
    );
};

export default SendTicketsDialog;
