import "./App.scss";
import {
    BrowserRouter as Router,
    useRoutes,
} from "react-router-dom";
import routes from "./config/routes.";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { HelmetProvider } from "react-helmet-async";

function AppRoutes() {
    const react_routes = useRoutes(routes);
    return react_routes;
}

const App = () => {
    return (
        <HelmetProvider>
            <Router>
                <AppRoutes />
                <ToastContainer
                    className="toast-position"
                    position="top-right"
                    autoClose={5000}
                    newestOnTop
                    closeOnClick
                    hideProgressBar
                    draggable={true}
                    pauseOnHover={true}
                    rtl={false}
                />
            </Router>
        </HelmetProvider>
    );
}

export default App;
