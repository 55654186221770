import {
    Box,
    Button,
    CircularProgress,
    DialogActions,
    Stack,
    Typography,
    useMediaQuery
} from "@mui/material";
import React from "react";
import GIF from "../../../../../../../../../../../assets/celeb.gif";
import { sendQRPassesForEvent } from "../../../../../../../../../../../services/wEvents/eventGuests";
import { useSelector } from "react-redux";
import { eventDetails } from "../../../../../../../../../../../redux/slices/eventDataSlice";
import { toast } from "react-toastify";

const SendTickets = ({
    ticket,
    sending,
    setSending,
    templateFields,
    payload,
    handleClose,
}) => {
    const eventData = useSelector(eventDetails);
    const isSmallDevice = useMediaQuery("(max-width:600px)");

    const sendQRPasses = () => {
        setSending(true);
        const formData = new FormData();
        formData.append("guest_list", payload.guestList);
        formData.append("organiser_name", payload.organiserName);
        formData.append("template_id", payload.templateId);
        const templateData = {};
        for (const field of templateFields) {
            templateData[field[0]] = payload[field[0]];
        }
        formData.append("template_data", JSON.stringify(templateData));
        sendQRPassesForEvent({
            eventId: eventData?.id,
            ticketId: ticket?.id,
            payload: formData
        })
            .then((res) => {
                setSending(false);
                toast.success(`QR passes will be sent to users`, {
                    position: "top-right",
                    theme: "dark"
                });
                handleClose(null, null, false); // to avoid opening close confirmation dialog
            })
            .catch((err) => {
                setSending(false);
                console.log(err);
                toast.error(Object.values(err.response.data)[0][0], {
                    position: "top-right",
                    theme: "dark"
                });
            });
    };

    return (
        <Stack
            spacing={2}
            alignItems="center"
            justifyContent="center"
            sx={{ py: { xs: 2 }, textAlign: "center" }}
        >
            <Box
                component="img"
                src={GIF}
                alt={"Loading"}
                width={isSmallDevice ? "100%" : "25rem"}
                height={isSmallDevice ? "10rem" : "15rem"}
            />
            <Typography>
                Congratulations! You have successfully completed all the steps,
                including payment. You can now Send QR Tickets.
            </Typography>
            <DialogActions>
                <Button
                    variant="contained"
                    color="warning"
                    onClick={sendQRPasses}
                    sx={{
                        backgroundColor: "#FF8359",
                        color: "#FFF",
                        marginTop: "10px"
                    }}
                    disabled={sending}
                >
                    {sending && (
                        <CircularProgress size={24} sx={{ marginRight: 2 }} />
                    )}
                    Send Tickets
                </Button>
            </DialogActions>
        </Stack>
    );
};

export default SendTickets;
