import React, { useState, useEffect, useContext, useRef } from 'react';
import { Stack, Typography, IconButton, TextField } from '@mui/material';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { EventGuestsContext } from '../../../Menubar/components/EventGuests/EventGuestContext';

const GuestCategoryDetail = ({ fix_category, fix_count, onTicketCountChange, availableCategoryTickets }) => {
  const categories = fix_category ? fix_category.split(',') : [];
  const counts = fix_count ? fix_count.split(',') : [];
  const { updatedCategoryCounts } = useContext(EventGuestsContext);
  const isFirstRender = useRef(true);

  // Always initialize with 0
  const initializeTicketCounts = () => {
    return categories.reduce((acc, category) => {
      acc[category] = 0; // Always start with 0
      return acc;
    }, {});
  };

  const [ticketCounts, setTicketCounts] = useState(initializeTicketCounts());

  // Update maxCounts based on updatedCategoryCounts
  const [maxCounts, setMaxCounts] = useState({});

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    if (updatedCategoryCounts && categories.length) {
      const newMaxCounts = {};
      categories.forEach((category, index) => {
        newMaxCounts[category] = parseInt(updatedCategoryCounts[index], 10) || 0;
      });
      setMaxCounts(newMaxCounts);
    }
  }, [updatedCategoryCounts, categories]);

  // Handle availableCategoryTickets updates
  useEffect(() => {
    if (availableCategoryTickets) {
      const newMaxCounts = {};
      categories.forEach((category, index) => {
        newMaxCounts[category] = availableCategoryTickets[index] || 0;

        // Reset count to 0 if available tickets is 0
        if (availableCategoryTickets[index] === 0) {
          setTicketCounts(prev => ({
            ...prev,
            [category]: 0
          }));
        }
      });
      setMaxCounts(newMaxCounts);
    }
  }, [availableCategoryTickets, categories]);

  useEffect(() => {
    if (onTicketCountChange) {
      const countValues = Object.values(ticketCounts);
      onTicketCountChange(countValues);
    }
  }, [ticketCounts, onTicketCountChange]);

  const getMaxCount = (category) => {
    return maxCounts[category] || 0;
  };

  const handlePlusTicketCount = (category) => {
    const maxCount = getMaxCount(category);
    setTicketCounts(prev => {
      const currentCount = prev[category];
      if (currentCount < maxCount) {
        return {
          ...prev,
          [category]: currentCount + 1
        };
      }
      return prev;
    });
  };

  const handleMinusTicketCount = (category) => {
    setTicketCounts(prev => {
      const currentCount = prev[category];
      if (currentCount > 0) {
        return {
          ...prev,
          [category]: currentCount - 1
        };
      }
      return prev;
    });
  };

  return (
    <div>
      {categories.length > 0 && categories.map((category, index) => {
        const currentCount = ticketCounts[category];
        const maxCount = getMaxCount(category);
        const displayMaxCount = availableCategoryTickets ?
          availableCategoryTickets[index] :
          (updatedCategoryCounts ? parseInt(updatedCategoryCounts[index], 10) : 0);

        return (
          <Stack direction="row" style={{ margin: '20px 0' }} spacing={1} alignItems="center" key={category}>
            <Typography>
              <b>Number of {category}:</b>&nbsp;
              <span style={{ color: '#666', fontSize: '0.9em' }}>
                (Available: {displayMaxCount})
              </span>
            </Typography>
            <Stack direction="row" spacing={1} alignItems="center">
              <IconButton
                onClick={() => handleMinusTicketCount(category)}
              >
                <RemoveCircleOutlineIcon
                  color="warning"
                />
              </IconButton>
              <TextField
                color="warning"
                sx={{ width: '4rem' }}
                inputProps={{
                  type: 'number',
                  min: 0,
                  max: maxCount
                }}
                value={currentCount}
                disabled={maxCount === 0}
                onChange={(e) => {
                  const newValue = Number(e.target.value);
                  if (newValue >= 0 && newValue <= maxCount) {
                    setTicketCounts(prev => ({
                      ...prev,
                      [category]: newValue,
                    }));
                  }
                }}
              />
              <IconButton
                onClick={() => handlePlusTicketCount(category)}
              >
                <AddCircleOutlineIcon
                  color="warning"
                />
              </IconButton>
            </Stack>
          </Stack>
        );
      })}
    </div>
  );
};

export default GuestCategoryDetail;