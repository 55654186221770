import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    Divider,
    MenuItem,
    Select,
    Stack,
    Switch,
    TextField,
    Typography,
    InputAdornment
} from "@mui/material";
import _ from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from '@mui/lab';
import { toast } from "react-toastify";
import { EventSettingsContext } from "../..";
import CostBreakdown from "../../../../../../../../components/CostBreakdown";
import { eventDetails } from "../../../../../../../../redux/slices/eventDataSlice";
import { createEventTicket, editEventTicket } from "../../../../../../../../services/wEvents/eventDetails";
import { ticketCurrencies } from "../../../../../../../../utils/constants/ticketCurrency";
import { gstOptions } from "../../../../../../../../utils/constants/ticketgstoptions";
import { getExtraChargesCosting } from "../../../../../../../../utils/functions/getExtraChargesCosting";
import { parseDecimalNumber } from "../../../../../../../../utils/functions/getFormattedNumber";
import UploadImage from "../../../../../../../../components/UploadImage";
import Box from "@mui/material/Box";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import CustomRadioGroup from "../../../../../../../../components/CustomRadioGroup";
import { manageEventTickerPayload } from "../../../../../../../../utils/functions/managePayload";
import { TICKET_TYPES } from "../../../../../../../../utils/constants/eventTickets";
import CustomTooltip from "../../../../../../../../components/CustomTooltip";
import SampleTicket1 from "../../../../../../../../assets/Sample_Ticket_1.jpeg";
import ImageViewer from "react-simple-image-viewer";
import FacilityCard from "./components/EventFacilities/FacilityCard";
import FacilityForm from "./components/EventFacilities/FacilityForm";
import EmailEditor from "../../../../../Menubar/components/EventGuests/components/GuestInvitation/Steps/EmailEditor";
import { IconButton } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Collapse } from "@mui/material";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';


const TicketForm = ({ edit, ticket, handleEditClose }) => {
    const eventData = useSelector(eventDetails);
    const FILE_SIZE = 10 * 1000 * 1000; // 4MB Size
    const { activeSlide, setSlide, setTickets } = useContext(EventSettingsContext);

    const [ticketImage, setTicketImage] = useState(null);
    const [loader, setLoader] = useState(false);
    const [samplePreviewOpen, setSamplePreviewOpen] = useState(false);
    const eventDetailsObj = useSelector(eventDetails);
    const eventTicketType = eventDetailsObj?.is_paid ? 'paid' : 'free';
    const [showAvailableTicketCount, setShowAvailableTicketCount] = useState(edit ? (ticket.show_available_tickets ? true : false) : false);
    const [showSoldoutStatus, setShowSoldoutStatus] = useState(edit ? (ticket.sold_out_status ? true : false) : false);
    const [isSeparated, setIsSeparated] = useState(false);
    const [editorContent, setContent] = useState(null) // For email message
    const [expanded, setExpanded] = useState(false); // State for controlling collapsible section
    const [emailSubject, setEmailSubject] = useState(edit ? ticket.custom_email_subject : null);
    const [add, setAdd] = useState(false);
    const schema = yup.object({
        title: yup.string().required(),
        quantity: yup.number().required(),
        amount: yup.number()
            .typeError('This is required field')
            .test('valid-amount', 'Amount must be 0 or at least 5', value => value === 0 || value >= 5)
            .test('is-integer', 'Amount must be an integer', value => Number.isInteger(value)),
        ticketType: yup.string().required(),
        ticket_photo: yup.mixed().optional()
            .test((value, context) => {
                if (context.parent?.ticketType === TICKET_TYPES.QR_CODE && (!value || value.length <= 0)) {
                    return context.createError({ message: "This field is required!" });
                }
                return true;
            })
            .test("fileSize", "File Size should be less than 10MB", (value, context) => {
                if (value?.length && (typeof value !== 'string') && value[0].size > FILE_SIZE) {
                    return context.createError({ message: 'File Size should be less than 10MB' })
                }
                return true;
            }),
    });

    const {
        handleSubmit, register, watch, control, setValue,
        formState: { errors }, reset, setError,
    } = useForm({
        defaultValues: {
            ticketType: edit ? ticket?.ticket_type : TICKET_TYPES.NORMAL,
            currency: edit ? ticket.currency : ticketCurrencies[0].value, //To show gst option only for rupees
            amount: edit ? parseDecimalNumber(ticket.amount, 2) : 0, // To show cost breakdown from component mount
            gst_percent: edit ? ticket.gst_percent : 0, // To show GST row in cost breakdown on edit if GST enabled 
            extra_charges_from_buyer: edit ? ticket.extra_charges_from_buyer : 0, // To show GST row in cost breakdown on edit if GST enabled
            ticket_photo: ticketImage,
            isDeletePhoto: false,
            isDeleteOldPhoto: false, // use this flag to delete photo when editing ticket image as isDeletePhoto will remain false
            cropDimensions: edit ? JSON.parse(ticket.crop_dimensions) : null,
            ...ticket
        },
        resolver: yupResolver(schema)
    });

    let ticketPrice = _.round(watch("amount"), 2);
    let ticketCurrency = watch("currency");
    let gst_percent = watch("gst_percent");
    let isDeletePhoto = watch("isDeletePhoto");
    let ticketType = watch("ticketType");
    let cropDimensions = watch('cropDimensions');
    const charges = getExtraChargesCosting(ticketPrice, gst_percent);
    const ticketSymbol = ticketCurrencies.find((t) => t.value === watch(ticketCurrency))?.symbol;
    const handleShowAvailableTicketsCount = (e) => {
        setShowAvailableTicketCount(e.target.checked);
        setValue("show_available_ticket_count", e.target.checked);
    }
    const handleShowSoldoutStatus = (e) => {
        setShowSoldoutStatus(e.target.checked);
        setValue("sold_out_status", e.target.checked);
    }
    const handleSeparatedTickets = (e) => {
        setIsSeparated(e.target.checked);
        setValue("is_separated", e.target.checked);
    }
    const handleExpandClick = () => {
        setExpanded(!expanded);
        if (!expanded && emailSubject === null) {
            setEmailSubject("Your {{Event Name}} Ticket Purchase");
        }
    };


    const [facilityDetails, setFacilityDetails] = useState(edit ? ticket.facilities : []);
    const handleAddEventFacility = (data) => {
        const facilityExists = facilityDetails.find(d => (d.name).toLowerCase() === (data.name).toLowerCase());
        if (facilityExists) {
            toast.error("Event Facility already exists", {
                position: "top-right",
                theme: "dark"
            });
            return;
        }
        setFacilityDetails((prevDetails) => [...prevDetails, data]);
        setAdd(false);
        toast.success("Event Facility added", {
            position: "top-right",
            theme: "dark"
        });
    }

    const handleEditEventFacility = (facility_name, updated_facility) => {
        const updatedFacilityDetails = facilityDetails.map(facility => {
            if (facility.name === facility_name) {
                // When facility name matches, update it with the provided updated facility
                return {
                    ...updated_facility,
                    ...(facility.id ? { id: facility.id } : {}) // Retain the id if it exists
                };
            }
            return facility; // For non-matching facilities, return them unchanged
        });
        setFacilityDetails(updatedFacilityDetails);
        toast.success("Event Facility updated", {
            position: "top-right",
            theme: "dark"
        });
    }
    const handleDeleteEventFacility = (facility_name) => {
        const facilityToDelete = facilityDetails.find(d => d.name === facility_name) //Because every facility has unique name
        setFacilityDetails((details) => details.filter((d) => d !== facilityToDelete));
    }
    const mySubmit = (data) => {
        setLoader(true);
        if (editorContent) {
            data.email_message = `<html><body>${editorContent}</body></html>`;
        }
        data.email_subject = emailSubject;
        if (data.ticketType === TICKET_TYPES.QR_CODE) {
            if (data.isDeletePhoto) {
                setError('ticket_photo', { message: "Ticket photo is required", type: 'required' });
                setLoader(false);
                return;
            }
            if (!Boolean(data.cropDimensions)) {
                setError('cropDimensions', { message: "Please select area to embed QR code", type: 'required' });
                setLoader(false);
                return;
            }
        }
        const payload = manageEventTickerPayload(data, edit, eventTicketType, facilityDetails);

        if (edit) {
            editEventTicket(payload, eventData?.id, ticket.id).then((res) => {
                handleEditClose();
                setTickets((tickets) => {
                    let i = tickets.findIndex((t) => t.id === ticket.id);
                    tickets[i] = res.data.data;
                    return [...tickets];
                });
                toast.success("Event ticket updated", {
                    position: "top-right",
                    theme: "dark"
                });
                setLoader(false);
            }).catch((err) => {
                setLoader(false);
                console.log(err);
                toast.error(Object.values(err.response.data)[0][0], {
                    position: "top-right",
                    theme: "dark"
                });
            });
        } else {
            createEventTicket(payload, eventData?.id).then((res) => {
                toast.success("Event ticket created successfully", {
                    position: "top-right",
                    theme: "dark"
                });
                setTickets((tickets) => {
                    return [...tickets, res.data.data];
                });
                setSlide(1);
                setLoader(false);
            }).catch((err) => {
                setLoader(false);
                console.log(err);
                toast.error(Object.values(err.response.data)[0][0], {
                    position: "top-right",
                    theme: "dark"
                });
            });
        }
    };

    useEffect(() => {
        return () => {
            reset();
            setShowAvailableTicketCount(false);
            setFacilityDetails([]);
            setAdd(false);
            setTicketImage(edit ? `${ticket.ticket_photo}` : null);
        };
    }, [activeSlide]);

    useEffect(() => {
        if (ticket && ticket.ticket_photo) {
            setTicketImage(`${ticket.ticket_photo}`);
        }
    }, [ticket]);

    useEffect(() => {
        // Automatically enable "Generate Separated Tickets" if facilities exist
        if (facilityDetails.length > 0) {
            setIsSeparated(true);
            setValue("is_separated", true); // Set form value
        } else {
            setIsSeparated(false);
        }
    }, [facilityDetails, setValue]);
    useEffect(() => {
        if (edit && ticket) {
            setIsSeparated(ticket.is_separated ? true : false);
        }
    }, [edit, ticket]);

    return (
        <>
            <form
                autoComplete="off"
                onSubmit={handleSubmit(mySubmit)}
            >
                <DialogContent dividers={true} sx={{
                    p: { xs: "1rem", md: "1.5rem 1.5rem" },
                    height: { xs: edit ? "20rem" : "26rem", md: edit ? "22rem" : "31rem" }
                }}>
                    <Stack spacing={3}>
                        <Box>
                            <Typography sx={{ fontSize: { xs: "1rem" } }} mb={1}>
                                Ticket Type
                            </Typography>
                            <Controller
                                control={control}
                                name="ticketType"
                                render={({ field }) => (
                                    <CustomRadioGroup
                                        value={field.value}
                                        handleRadioChange={field.onChange}
                                        option1={TICKET_TYPES.NORMAL}
                                        option2={TICKET_TYPES.QR_CODE}
                                        gridCols={{
                                            xs: 12,
                                            sm: 5.5,
                                            lg: 4,
                                        }}
                                        disabled={eventDetailsObj.is_self_check_in ? true : false}
                                        option2Props={{
                                            badge: true,
                                            badgeContent: "Paid",
                                            extraItem: (
                                                <Stack alignItems='center' justifyContent='center'
                                                    sx={{ pr: 1 }}>
                                                    <CustomTooltip
                                                        content={
                                                            <Stack spacing={1} sx={{ p: 0.5 }}>
                                                                <Typography sx={{ fontSize: '0.75rem' }}>
                                                                    You can add a QR Code to your customized ticket using this feature.
                                                                </Typography>
                                                            </Stack>
                                                        }
                                                        placement='bottom'
                                                    />
                                                </Stack>
                                            )
                                        }}
                                    />
                                )}
                            />
                        </Box>

                        <Divider dir="horizontal" />

                        {ticketType === TICKET_TYPES.QR_CODE ? (
                            <Box display='flex' justifyContent='center' alignItems='center' gap={2}>
                                <Box
                                    sx={{
                                        backgroundColor: ticketImage && !isDeletePhoto ? "#171717" : "white",
                                        borderRadius: "10px",
                                        width: { xs: "60%", sm: "50%", lg: "40%" },
                                        // marginInline: "auto !important",
                                        height: "9rem",
                                        position: "relative",
                                        border: errors.ticket_photo ? "1px solid red" : "1px solid lightgrey"
                                    }}
                                >
                                    <UploadImage
                                        fieldName="ticket_photo"
                                        label="QRCode Ticket Photo"
                                        register={register}
                                        errors={errors}
                                        selectedImage={isDeletePhoto ? null : ticketImage}
                                        setSelectedImage={(v) => {
                                            setTicketImage(v);
                                            if (isDeletePhoto) {
                                                setValue("isDeletePhoto", false);
                                            }
                                            if (edit) {
                                                setValue("isDeleteOldPhoto", true)
                                            }
                                        }}
                                        hideVisibility={edit}
                                        hideOptions={false}
                                        isDeletable={edit}
                                        onDelete={() => {
                                            setValue("isDeletePhoto", true);
                                            setValue('ticket_photo', null);
                                            setTicketImage(null);
                                        }}
                                        allowCrop={ticketType === TICKET_TYPES.QR_CODE}
                                        cropDimensions={cropDimensions}
                                        updatePercentageCrop={(crop) => {
                                            setValue('cropDimensions', crop);
                                        }}
                                    />
                                </Box>
                                <Box>
                                    <Button
                                        color='warning'
                                        onClick={() => setSamplePreviewOpen(true)}
                                    >
                                        See Sample Tickets
                                    </Button>
                                    <Dialog
                                        open={samplePreviewOpen}
                                        onClose={() => setSamplePreviewOpen(false)}
                                    >
                                        <ImageViewer
                                            src={[SampleTicket1]}
                                            currentIndex={0}
                                            disableScroll={true}
                                            closeOnClickOutside={true}
                                            onClose={() => setSamplePreviewOpen(false)}
                                            backgroundStyle={{ zIndex: 1200 }}
                                        />
                                    </Dialog>
                                </Box>
                            </Box>
                        ) : null}
                        {errors.cropDimensions ? (
                            <Typography
                                sx={{
                                    color: "red",
                                    fontWeight: 400,
                                    lineHeight: 1.66,
                                    fontSize: "0.8rem",
                                    textAlign: "center",
                                    marginTop: '0 !important',
                                }}
                            >
                                {_.get(errors, 'cropDimensions').message}
                            </Typography>
                        ) : null}

                        <TextField
                            fullWidth
                            color="warning"
                            label={`${ticketType === TICKET_TYPES.QR_CODE ? 'QRCode' : ''} Ticket Name/Type`}
                            placeholder="Standard, Early Bird, Premium"
                            defaultValue={edit ? ticket.title : null}
                            error={errors?.title ? true : false}
                            helperText={
                                errors?.title ? "This is required field" : null
                            }
                            {...register("title", { required: true })}
                        />
                        <TextField
                            fullWidth
                            color="warning"
                            label={`${ticketType === TICKET_TYPES.QR_CODE ? 'QRCode' : ''} Total Quantity`}
                            inputProps={{ type: "number" }}
                            defaultValue={edit ? ticket.quantity : null}
                            error={errors?.quantity ? true : false}
                            helperText={errors?.quantity ? "This is a required field" : null}
                            {...register("quantity", { required: true })}
                            InputProps={{
                                endAdornment: ticketType === TICKET_TYPES.QR_CODE && (
                                    <InputAdornment position="end">
                                        <CustomTooltip
                                            content={
                                                <Stack spacing={1} sx={{ p: 0.5 }}>
                                                    <Typography sx={{ fontSize: '0.75rem' }}>
                                                        <li>
                                                            Upto 100: 5₹ per guest
                                                        </li>
                                                        <li>
                                                            Upto 500: 4₹ per guest
                                                        </li>
                                                        <li>
                                                            Upto 1000: 3₹ per guest
                                                        </li>
                                                        <li>
                                                            Above 1000: 2₹ per guest
                                                        </li>
                                                        <li>
                                                            Above 5000: 1.5₹ per guest
                                                        </li>
                                                        <li>
                                                            Above 10000: 1.25₹ per guest
                                                        </li>
                                                        Plus 18% GST on above pricing
                                                    </Typography>
                                                </Stack>
                                            }
                                            placement='bottom'
                                        >
                                        </CustomTooltip>
                                    </InputAdornment>
                                )
                            }}
                        />

                        <Stack direction='row' alignItems='center' justifyContent='space-between'>
                            <Typography sx={{ fontSize: { xs: '1rem' } }}>
                                Show Available Tickets Count
                            </Typography>
                            <Switch
                                color='warning'
                                checked={showAvailableTicketCount}
                                onChange={(e) => handleShowAvailableTicketsCount(e)}
                            />
                        </Stack>
                        <Stack direction='row' alignItems='center' justifyContent='space-between'>
                            <Typography sx={{ fontSize: { xs: '1rem' } }}>
                                Soldout status
                            </Typography>
                            <Switch
                                color='warning'
                                checked={showSoldoutStatus}
                                onChange={(e) => handleShowSoldoutStatus(e)}
                            />
                        </Stack>

                        <TextField
                            fullWidth
                            color="warning"
                            label="Max. Tickets Buyer Can Buy"
                            inputProps={{ type: "number", min: 1, max: watch("quantity") }}
                            defaultValue={edit ? ticket.max_ticket_limit : null}
                            {...register("max_ticket_limit")}
                        />
                        {
                            (watch("max_ticket_limit") > 1) && (
                                <Stack direction='row' alignItems='center' justifyContent='space-between'>
                                    <Typography sx={{ fontSize: { xs: '1rem' } }}>
                                        Generate Separated Tickets
                                    </Typography>
                                    <Switch
                                        color='warning'
                                        checked={isSeparated}
                                        onChange={(e) => handleSeparatedTickets(e)}
                                        disabled={facilityDetails.length > 0} // Disable if facilities are present
                                    />
                                </Stack>
                            )
                        }

                        {
                            eventTicketType === 'paid' ?
                                <>

                                    <Stack direction="row" spacing={2}>
                                        <Controller
                                            name="currency"
                                            control={control}
                                            defaultValue={edit ? ticket.currency : ticketCurrencies[0].value}
                                            render={({ field: { onChange, value } }) => (
                                                <Select value={value} onChange={(event) => {
                                                    // Custom logic here
                                                    const selectedValue = event.target.value;
                                                    if (selectedValue !== "rupees") {
                                                        setValue("gst_percent", 0);
                                                    }

                                                    // Call the original onChange function
                                                    onChange(event);
                                                }}>
                                                    {ticketCurrencies.map((option, index) => (
                                                        <MenuItem key={index} value={option.value}>
                                                            {option.label}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            )}
                                        />
                                        <TextField
                                            fullWidth
                                            color="warning"
                                            label={`${ticketType === TICKET_TYPES.QR_CODE ? 'QRCode' : ''} Ticket Price`}
                                            defaultValue={edit ? ticket.amount : null}
                                            error={errors?.amount ? true : false}
                                            helperText={
                                                errors?.amount ? errors.amount.message : null
                                            }
                                            {...register("amount", { required: true })}
                                        />
                                    </Stack>

                                    <Stack spacing={1}>
                                        {watch("currency") === "rupees" ?
                                            <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
                                                <Typography sx={{ fontSize: { xs: "1rem" } }}>
                                                    GST on {ticketType === TICKET_TYPES.QR_CODE ? 'QRCode' : ''} Ticket Price
                                                </Typography>
                                                <Controller
                                                    name="gst_percent"
                                                    control={control}
                                                    defaultValue={edit ? ticket.gst_percent : gstOptions[0].value}
                                                    render={({ field: { onChange, value } }) => (
                                                        <Select size="small" value={value} onChange={onChange} color="warning">
                                                            {gstOptions.map((option, index) => (
                                                                <MenuItem key={index} value={option.value}>
                                                                    {option.label}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    )}
                                                />
                                            </Stack>
                                            : null
                                        }

                                        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
                                            <Typography sx={{ fontSize: { xs: "1rem" } }}>
                                                Additional Charges Paid by Buyer?
                                            </Typography>
                                            <Controller
                                                control={control}
                                                name="extra_charges_from_buyer"
                                                defaultValue={edit ? (ticket.extra_charges_from_buyer ? true : false) : false}
                                                render={(props) => (
                                                    <Switch
                                                        color="warning"
                                                        onChange={(e) => props.field.onChange(e.target.checked)}
                                                        checked={props.field.value ? true : false}
                                                    />
                                                )}
                                            />
                                        </Stack>
                                    </Stack>

                                    <CostBreakdown
                                        model={ticketType === TICKET_TYPES.QR_CODE ? 'QRCode Ticket' : "Ticket"}
                                        watch={watch}
                                        charges={charges}
                                        actualPrice={ticketPrice}
                                        gstPercent={gst_percent}
                                        priceSymbol={ticketSymbol}
                                    />
                                    {gst_percent !== 0 ?
                                        <TextField
                                            fullWidth
                                            color="warning"
                                            label="GST number"
                                            // placeholder="Enter your"
                                            defaultValue={edit ? ticket.gst_number : null}
                                            {...register("gst_number")}
                                        />
                                        : null
                                    }
                                </> :
                                <></>
                        }

                        {
                            ticketType === TICKET_TYPES.QR_CODE ?
                                <>
                                    {
                                        facilityDetails.filter(facility => facility.is_included).length > 0 ?
                                            <>
                                                <Box>
                                                    <Typography sx={{ fontWeight: "500", fontSize: "15px", display: "inline-block" }}>
                                                        Included Facilities:
                                                    </Typography>
                                                </Box>
                                                {
                                                    facilityDetails
                                                        .filter(facility => facility.is_included)
                                                        ?.map((item, i) => (
                                                            <FacilityCard
                                                                key={i}
                                                                facility={item}
                                                                handleEditEventFacility={handleEditEventFacility}
                                                                handleDeleteEventFacility={handleDeleteEventFacility} />
                                                        ))
                                                }
                                            </> : null
                                    }
                                    {
                                        facilityDetails.filter(facility => !facility.is_included).length > 0 ?
                                            <>
                                                <Box>
                                                    <Typography sx={{ fontWeight: "500", fontSize: "15px", display: "inline-block" }}>
                                                        Additional Facilities:
                                                    </Typography>
                                                </Box>
                                                {
                                                    facilityDetails
                                                        .filter(facility => !facility.is_included)
                                                        ?.map((item, i) => (
                                                            <FacilityCard
                                                                key={i}
                                                                facility={item}
                                                                handleEditEventFacility={handleEditEventFacility}
                                                                handleDeleteEventFacility={handleDeleteEventFacility} />
                                                        ))
                                                }
                                            </> : null
                                    }
                                    <Divider sx={{ my: 1 }} />
                                    {add ?
                                        <Box sx={{
                                            margin: '1rem auto !important',
                                            width: '90%',
                                            borderRadius: '5px',
                                            boxShadow: '0 0 3px 3px rgba(0, 0, 50, 0.15)',
                                        }}>
                                            <FacilityForm setAdd={setAdd} handleAddEventFacility={handleAddEventFacility} />
                                        </Box> :
                                        <>
                                            <div style={{ display: "flex", justifyContent: "center" }}>

                                                <Button variant="text" color="warning" sx={{ width: 'auto' }} onClick={() => setAdd(true)}>+ Add Facility

                                                </Button>
                                                <CustomTooltip
                                                    content={
                                                        <Stack spacing={1} sx={{ p: 0.5, marginLeft: "5px" }}>
                                                            <Typography sx={{ fontSize: '0.75rem' }}>
                                                                1₹ for each facilities & Gst 18% Extra
                                                            </Typography>
                                                        </Stack>
                                                    }
                                                    placement='bottom'
                                                >
                                                </CustomTooltip>
                                            </div>
                                        </>
                                    }
                                </> :
                                null
                        }

                        <TextField
                            rows={3}
                            multiline
                            fullWidth
                            color="warning"
                            label={`${ticketType === TICKET_TYPES.QR_CODE ? 'QRCode' : ''} Ticket Description`}
                            defaultValue={edit ? ticket.description : null}
                            {...register("description")}
                        />
                        <Stack direction='row' alignItems='center' justifyContent='space-between' spacing={2}>
                            <Typography sx={{ fontSize: { xs: '1rem' } }}>
                                Email
                            </Typography>
                            <Stack direction='row' alignItems='center' spacing={1}>
                                <IconButton
                                    onClick={handleExpandClick}
                                    aria-expanded={expanded}
                                    aria-label="show more"
                                    sx={{
                                        transition: 'transform 0.3s',
                                    }}
                                >
                                    {expanded ? (
                                        <ExpandMoreIcon sx={{ color: '#1F274A' }} />
                                    ) : (
                                        <ArrowForwardIosIcon sx={{ color: '#1F274A' }} />
                                    )}
                                </IconButton>
                            </Stack>
                        </Stack>

                        <Collapse in={expanded} timeout="auto" unmountOnExit>
                            <Typography sx={{ fontSize: '0.9rem', color: 'gray', my: 1 }}>
                                {
                                    "Note: If you want to use the Event Name, Guest Name, Ticket Name, and Number of Tickets in your email, you need to include the placeholders {{Event Name}}, {{Guest Name}}, {{Ticket Name}}, and {{Num of Tickets}}."
                                }
                            </Typography>
                            <TextField
                                fullWidth
                                color="warning"
                                label="Email Subject"
                                value={emailSubject}
                                onChange={(e) => setEmailSubject(e.target.value)}
                                sx={{ my: 2 }}
                            />
                            <Box sx={{ my: 2 }}>
                                <EmailEditor
                                    editorContent={editorContent}
                                    setContent={setContent}
                                    selfCheckin={eventDetailsObj.is_self_check_in}
                                    ticketData={ticket}
                                    ticketPrice={ticketPrice}
                                    ticketCurrency={ticketCurrency}
                                />
                            </Box>
                        </Collapse>

                        {eventTicketType === 'paid' && ticketType === TICKET_TYPES.NORMAL ? (
                            <Box
                                sx={{
                                    backgroundColor: ticketImage && !isDeletePhoto ? "#171717" : "white",
                                    borderRadius: "10px",
                                    width: { xs: "60%", sm: "50%", lg: "40%" },
                                    marginInline: "auto !important",
                                    height: "9rem",
                                    position: "relative",
                                    border: errors.ticket_photo ? "1px solid red" : "1px solid lightgrey"
                                }}
                            >
                                <UploadImage
                                    fieldName="ticket_photo"
                                    label="Ticket Photo"
                                    register={register}
                                    errors={errors}
                                    selectedImage={isDeletePhoto ? null : ticketImage}
                                    setSelectedImage={(v) => {
                                        setTicketImage(v);
                                        if (edit) {
                                            setValue("isDeleteOldPhoto", true)
                                        }
                                    }}
                                    hideVisibility={edit}
                                    hideOptions={false}
                                    isDeletable={edit}
                                    onDelete={() => setValue("isDeletePhoto", true)}
                                />
                            </Box>
                        ) : null}
                    </Stack>
                </DialogContent>
                <DialogActions sx={{ mr: 1, my: 0.5 }}>
                    <Button
                        variant="outlined"
                        color="warning"
                        onClick={() => {
                            setTicketImage(edit ? `${ticket.ticket_photo}` : null);
                            setValue('cropDimensions', edit ? JSON.parse(ticket.crop_dimensions) : null);
                            setFacilityDetails(edit ? ticket.facilities : []);
                            reset();
                        }}
                    >
                        Reset
                    </Button><LoadingButton
                        type="submit"
                        color="warning"
                        variant="contained"
                        loading={loader ? true : false}
                        startIcon={loader ? <SaveIcon /> : null}
                        loadingPosition={loader ? "start" : null}
                    >
                        {edit ? (loader ? 'Saving...' : 'Save') : (loader ? 'Creating...' : 'Create')}
                    </LoadingButton>
                </DialogActions>
            </form>
        </>
    );
};

export default TicketForm;
