import {
    Box,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    useTheme
} from "@mui/material";
import React from "react";
import { parseDecimalNumber } from "../../../../../../../../../../../utils/functions/getFormattedNumber";
import getGeneratedOrSendTicketCharges from "../../../../../../../../../../../utils/functions/getGeneratedOrSendTicketCharges";
import QRTicketsPayment from "../../QRTicketsPayment";

const PurchaseOrder = ({
    passQuantity,
    includedFacilities,
    loading,
    paymentDone
}) => {
    const theme = useTheme();
    const GST_PERCENT = process.env.REACT_APP_GST_PERCENT_FOR_SEND_MESSAGE;

    let passSubTotal =
        getGeneratedOrSendTicketCharges(passQuantity) * passQuantity;
    const includedFacilityCount = includedFacilities.length;
    // Adding 1 rupee for each included facility per pass
    const totalFacilityCharge = includedFacilityCount * passQuantity;
    let subTotal = passSubTotal + totalFacilityCharge;

    let gstCharge = (GST_PERCENT / 100) * subTotal;
    gstCharge = parseDecimalNumber(gstCharge, 2);
    const invoiceTotal = subTotal + gstCharge;

    return (
        <>
            <Stack spacing={2} sx={{ px: { xs: 0, sm: 1, lg: 2 }, pb: 2 }}>
                <Box>
                    <Typography
                        variant="body2"
                        sx={{ mt: 1, color: "#6F738B", fontSize: "0.9rem" }}
                    >
                        Please confirm the details before proceeding to payment.
                    </Typography>
                    <TableContainer
                        sx={{
                            border: "1px solid lightgrey",
                            width: { md: "90%" },
                            margin: "1rem auto !important"
                        }}
                    >
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Type</TableCell>
                                    <TableCell align="center">Qty</TableCell>
                                    <TableCell align="center">
                                        Per Pass/Ticket Cost
                                    </TableCell>
                                    <TableCell align="center">Total</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow hover={true}>
                                    <TableCell>Pass/Ticket</TableCell>
                                    <TableCell align="center">
                                        {passQuantity}
                                    </TableCell>
                                    <TableCell align="center">
                                        {parseDecimalNumber(
                                            getGeneratedOrSendTicketCharges(
                                                passQuantity
                                            )
                                        )}{" "}
                                        &#8377;
                                    </TableCell>
                                    <TableCell align="center">
                                        {parseDecimalNumber(passSubTotal, 2)}{" "}
                                        &#8377;
                                    </TableCell>
                                </TableRow>
                                {includedFacilities.length > 0 ? (
                                    <TableRow hover={true}>
                                        <TableCell>
                                            <Typography
                                                sx={{ fontWeight: "bold" }}
                                            >
                                                Facilities
                                            </Typography>
                                            <Stack
                                                direction="row"
                                                flexWrap="wrap"
                                                sx={{ mt: 1, p: 0 }}
                                            >
                                                {includedFacilities
                                                    .map(
                                                        (facility) =>
                                                            facility.name
                                                    )
                                                    .join(", ")}
                                            </Stack>
                                        </TableCell>
                                        <TableCell align="center">
                                            {passQuantity}
                                        </TableCell>
                                        <TableCell align="center">
                                            {includedFacilityCount} &#8377;
                                        </TableCell>
                                        <TableCell align="center">
                                            {parseDecimalNumber(
                                                totalFacilityCharge,
                                                2
                                            )}{" "}
                                            &#8377;
                                        </TableCell>
                                    </TableRow>
                                ) : null}
                                <TableRow hover={true}>
                                    <TableCell colSpan={3}>Sub Total</TableCell>
                                    <TableCell align="center">
                                        {parseDecimalNumber(subTotal, 2)}{" "}
                                        &#8377;
                                    </TableCell>
                                </TableRow>
                                <TableRow hover={true}>
                                    <TableCell colSpan={3}>
                                        GST ({GST_PERCENT}%)
                                    </TableCell>
                                    <TableCell align="center">
                                        {parseDecimalNumber(gstCharge, 2)}{" "}
                                        &#8377;
                                    </TableCell>
                                </TableRow>
                                <TableRow hover={true}>
                                    <TableCell colSpan={3}>
                                        <Typography
                                            sx={{
                                                color: "#1F274A",
                                                fontSize: "1.2rem",
                                                fontWeight: "bold"
                                            }}
                                        >
                                            Final Amount
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Typography>
                                            {parseDecimalNumber(
                                                invoiceTotal,
                                                2
                                            )}{" "}
                                            &#8377;
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>

                {invoiceTotal > 0 ? (
                    <Stack spacing={2}>
                        <Box sx={{ textAlign: "center" }}>
                            <QRTicketsPayment
                                loading={loading}
                                paymentDone={paymentDone}
                            />
                        </Box>
                        <Typography
                            sx={{
                                mt: 1,
                                textAlign: "center",
                                color: "#6F738B",
                                fontSize: "0.8rem"
                            }}
                        >
                            <span
                                style={{
                                    color: theme.palette.primaryColor
                                }}
                            >
                                Note:
                            </span>{" "}
                            Minimum 1 Rs. payment will be taken as per razorpay
                            standards!!
                        </Typography>
                    </Stack>
                ) : null}
            </Stack>
        </>
    );
};

export default PurchaseOrder;
