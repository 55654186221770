import { toast } from "react-toastify";
import { ForceMetaUpdate } from '../ForceMetaUpdate/ForceMetaUpdate';
import moment from "moment";

export const generateAndCopyCommonEventLink = (id, eventData) => {
    const currentOrigin = window.location.origin;
    const seoTitle = eventData.title.replace(/\s+|\/+/g, '-').toLowerCase();
    const commonLink = `${currentOrigin}/e/${id}/${seoTitle}`;

    // Get proper image URL
    const imageUrl = eventData.event_main_photo ?
        (eventData.event_main_photo.startsWith('http') ?
            eventData.event_main_photo :
            `${process.env.REACT_APP_BACKEND_URL}${eventData.event_main_photo}`
        ) :
        `${currentOrigin}/wowslyFavicon.webp`;

    // Create meta description
    const description = `${eventData.title} - ${eventData.category || ''} ${eventData.schedule_announcement_description ||
        (eventData.start_date ? moment(eventData.start_date).format("ddd, Do MMM YYYY") : '')
        }`.trim();

    // Update meta tags for sharing
    ForceMetaUpdate(
        eventData.title,
        description,
        imageUrl,
        commonLink
    );

    // Copy link to clipboard
    navigator.clipboard.writeText(commonLink);

    // Show success message
    toast.success("Event Link copied to clipboard!", {
        position: "top-right",
        theme: "dark"
    });

    return commonLink;
};